import { Box, Button, Typography } from "@material-ui/core"
import React from "react"
import { AddIcon } from "src/components/Icons/Add"

const Header = ({ classes, handleOpenModal, branchName, role }) => {
  return (
    <Box className={classes.header}>
      <Typography variant="h3">{branchName || "Branches"}</Typography>
      {!branchName && (
        <Box>
          {role === "owner" && (
            <Button
              variant="contained"
              color="secondary"
              size="large"
              startIcon={<AddIcon />}
              className={classes.addBtn}
              onClick={() => handleOpenModal({ name: "", address: "" })}
            >
              Add Branch
            </Button>
          )}
        </Box>
      )}
    </Box>
  )
}

export default Header
