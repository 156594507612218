import { connect } from "react-redux"

function Gate({ permissions, gate, children }) {
  const renderContent = () => {
    if (gate === undefined || permissions?.includes(gate)) {
      return children
    }
    return null
  }
  return renderContent()
}

const mapStateToProps = ({ session }) => ({ permissions: session.user.permissions })

export default connect(mapStateToProps)(Gate)
