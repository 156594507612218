export const fetchMenuPayload = {
  error: { message: "Error fetching menus", severity: "error" },
}

export const createMenuPayload = {
  success: { message: "Menu uploaded successfully", severity: "success" },
  error: { message: "Error uploading menu", severity: "error" },
}

export const createMenuV2Payload = {
  success: { message: "Menu created successfully", severity: "success" },
  error: { message: "Error creating menu", severity: "error" },
}

export const updateMenuV2Payload = {
  success: { message: "Menu updated successfully", severity: "success" },
  error: { message: "Error updating menu", severity: "error" },
}

export const addItemsPayload = {
  success: { message: "Items added successfully", severity: "success" },
  error: { message: "Error adding menu", severity: "error" },
}

export const createItemPayload = {
  success: { message: "Item created successfully", severity: "success" },
  error: { message: "Error creating item", severity: "error" },
}

export const updateItemPayload = {
  success: { message: "Item updated successfully", severity: "success" },
  error: { message: "Error updating item", severity: "error" },
}

export const deleteItemPayload = {
  success: { message: "Item deleted successfully", severity: "success" },
  error: { message: "Error deleting item", severity: "error" },
}

export const createGroupPayload = {
  success: { message: "Category created successfully", severity: "success" },
  error: { message: "Error creating Category", severity: "error" },
}

export const updateGroupPayload = {
  success: { message: "Category updated successfully", severity: "success" },
  error: { message: "Error updating Category", severity: "error" },
}

export const deleteGroupPayload = {
  success: { message: "Category deleted successfully", severity: "success" },
  error: { message: "Error deleting Category", severity: "error" },
}

export const createBrandPayload = {
  success: { message: "Brand created successfully", severity: "success" },
  error: { message: "Error creating brand", severity: "error" },
}

export const createCoverPayload = {
  success: { message: "Cover uploaded successfully", severity: "success" },
  error: { message: "Error uploading cover", severity: "error" },
}

export const updateBrandPayload = {
  success: { message: "Brand updated successfully", severity: "success" },
  error: { message: "Error updating brand", severity: "error" },
}

export const createLogoPayload = {
  success: { message: "Logo uploaded successfully", severity: "success" },
  error: { message: "Error uploading Logo", severity: "error" },
}

export const coversCountPayload = {
  error: { message: "Please select five images only", severity: "error" },
}

export const deleteMenuPayload = {
  success: { message: "Menu deleted successfully", severity: "success" },
  error: { message: "Error deleting menu", severity: "error" },
}

export const retrieveCardPayload = {
  error: { message: "Error fetching card", severity: "error" },
}

export const createCardPayload = {
  success: { message: "Card Created successfully", severity: "success" },
  error: { message: "Error Creating Card", severity: "error" },
}

export const updateCardPayload = {
  success: { message: "Card Updated successfully", severity: "success" },
  error: { message: "Error Updated Card", severity: "error" },
}

export const deleteCardPayload = {
  success: { message: "Card deleted successfully", severity: "success" },
  error: { message: "Error deleting card", severity: "error" },
}

export const approveStaffPayload = {
  success: { message: "Member approved successfully", severity: "success" },
  error: { message: "Error approve Member", severity: "error" },
}

export const deleteStaffPayload = {
  success: { message: "Member deleted successfully", severity: "success" },
  error: { message: "Error deleting member", severity: "error" },
}

export const updateProfilePayload = {
  success: { message: "Your profile updated successfully", severity: "success" },
  error: { message: "Error updating your profile", severity: "error" },
}

export const updatePasswordPayload = {
  success: { message: "Your password updated successfully", severity: "success" },
  error: { message: "Error updating your password", severity: "error" },
}

export const subscribePayload = {
  success: { message: "You subscribed successfully", severity: "success" },
  error: { message: "Error subscribing", severity: "error" },
}

export const createSurveyPayload = {
  success: { message: "Survey created successfully", severity: "success" },
  error: { message: "Error creating survey", severity: "error" },
}

export const updateSurveyPayload = {
  success: { message: "Survey updated successfully", severity: "success" },
  error: { message: "Error updating survey", severity: "error" },
}

export const createFolderPayload = {
  success: { message: "Folder created successfully", severity: "success" },
  error: { message: "Error creating folder", severity: "error" },
}

export const updateFolderPayload = {
  success: { message: "Folder updated successfully", severity: "success" },
  error: { message: "Error updating folder", severity: "error" },
}
export const deleteFolderPayload = {
  success: { message: "Folder deleted successfully", severity: "success" },
  error: { message: "Error deleting folder", severity: "error" },
}

export const deleteSurveyPayload = {
  success: { message: "Survey deleted successfully", severity: "success" },
  error: { message: "Error deleting survey", severity: "error" },
}

export const registerPayload = {
  success: { message: "You registered successfully", severity: "success" },
  error: { message: "Error register your account", severity: "error" },
}

export const notFoundPayload = {
  error: { message: "Content not found", severity: "error" },
}

export const noInternetPayload = {
  error: {
    message: "Please check your internet connection",
    severity: "error",
  },
}

export const generalErrorPayload = {
  error: { message: "Something went wrong", severity: "error" },
}

export const uploadImagePayload = {
  error: {
    message: "invalid file type please upload image file",
    severity: "error",
  },
}

export const exportDashboardPayload = {
  error: {
    message: "oops, something went wrong!",
    severity: "error",
  },
}

export const createBranchPayload = {
  success: { message: "Branch created successfully", severity: "success" },
  error: { message: "Error creating branch", severity: "error" },
}

export const updateBranchPayload = {
  success: { message: "Branch updated successfully", severity: "success" },
  error: { message: "Error updating branch", severity: "error" },
}

export const deleteBranchPayload = {
  success: { message: "Branch deleted successfully", severity: "success" },
  error: { message: "Error deleting branch", severity: "error" },
}

export const createSprintPayload = {
  success: { message: "Sprint created successfully", severity: "success" },
  error: { message: "Error creating sprint", severity: "error" },
}

export const updateSprintPayload = {
  success: { message: "Sprint updated successfully", severity: "success" },
  error: { message: "Error updating sprint", severity: "error" },
}

export const createCouponsPayload = {
  success: { message: "Coupon created successfully", severity: "success" },
  error: { message: "Error creating coupon", severity: "error" },
}

export const updateOrderPayload = {
  success: { message: "Order updated successfully", severity: "success" },
  error: { message: "Error updating order", severity: "error" },
}

export const createOrderPayload = {
  success: { message: "Item added successfully", severity: "success" },
  error: { message: "Error creating order", severity: "error" },
}

export const createRewardPayload = {
  success: { message: "Reward created successfully", severity: "success" },
  error: { message: "Error creating reward", severity: "error" },
}

export const joinQRPayload = {
  success: { message: "You Joined QR Card successfully", severity: "success" },
  error: { message: "Error Joining QR Card", severity: "error" },
}

export const createAreaPayload = {
  success: { message: "Area created successfully", severity: "success" },
  error: { message: "Error creating area", severity: "error" },
}

export const updateAreaPayload = {
  success: { message: "Area updated successfully", severity: "success" },
  error: { message: "Error updating area", severity: "error" },
}
export const deleteAreaPayload = {
  success: { message: "Area deleted successfully", severity: "success" },
  error: { message: "Error deleting area", severity: "error" },
}
export const createRegionPayload = {
  success: { message: "Region created successfully", severity: "success" },
  error: { message: "Error creating region", severity: "error" },
}

export const updateRegionPayload = {
  success: { message: "Region updated successfully", severity: "success" },
  error: { message: "Error updating region", severity: "error" },
}
export const deleteRegionPayload = {
  success: { message: "Region deleted successfully", severity: "success" },
  error: { message: "Error deleting region", severity: "error" },
}

export const createTemplatePayload = {
  success: { message: "Template created successfully", severity: "success" },
  error: { message: "Error creating template", severity: "error" },
}

export const updateTemplatePayload = {
  success: { message: "Template updated successfully", severity: "success" },
  error: { message: "Error updating template", severity: "error" },
}

export const deleteTemplatePayload = {
  success: { message: "Template deleted successfully", severity: "success" },
  error: { message: "Error deleting template", severity: "error" },
}

export const createShortenedURLPayload = {
  success: { message: "URL shortened successfully", severity: "success" },
  error: { message: "Error shortening url", severity: "error" },
}

export const updateShortenedURLPayload = {
  success: { message: "Shortened URL updated successfully", severity: "success" },
  error: { message: "Error updating shortened URL", severity: "error" },
}

export const deleteShortenedURLPayload = {
  success: { message: "Shortened URL deleted successfully", severity: "success" },
  error: { message: "Error deleting shortened URL", severity: "error" },
}
