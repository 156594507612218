/* eslint-disable no-restricted-globals */
import React, { useRef, useState } from "react"
import { Link as RouterLink } from "react-router-dom"
import { useDispatch } from "react-redux"
import {
  Avatar,
  Box,
  ButtonBase,
  Hidden,
  Menu,
  MenuItem,
  Typography,
  makeStyles,
  LinearProgress,
} from "@material-ui/core"
import { logoutUser } from "src/store/actions"
import { getCurrentUser } from "src/utils/auth"
import PersonOutlineOutlinedIcon from "@material-ui/icons/PersonOutlineOutlined"
import { getBrandDetailsRoute, joinQRRoute, privacyRoute, profileRoute, subscribeRoute } from "src/constants"
import CameraAltOutlinedIcon from "@material-ui/icons/CameraAltOutlined"
import ExitToAppOutlinedIcon from "@material-ui/icons/ExitToAppOutlined"
import BrandingWatermarkOutlinedIcon from "@material-ui/icons/BrandingWatermarkOutlined"
import LockIcon from "@material-ui/icons/Lock"
import CropFreeIcon from "@material-ui/icons/CropFree"

const useStyles = makeStyles((theme) => ({
  avatar: {
    height: 32,
    width: 32,
    marginRight: theme.spacing(1),
  },
  popover: {
    width: 200,
  },
  logOut: {
    marginTop: 30,
    color: "#EB5757",
  },
}))

const Account = ({ staff }) => {
  const classes = useStyles()
  const ref = useRef(null)
  const [isOpen, setOpen] = useState(false)
  const dispatch = useDispatch()
  const { user } = getCurrentUser()

  const handleOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  const handleLogout = () => {
    dispatch(logoutUser())
  }

  if (!staff) return <LinearProgress />

  return (
    <>
      <Box display="flex" alignItems="center" component={ButtonBase} onClick={handleOpen} ref={ref}>
        <Avatar alt="User" className={classes.avatar} src={user.photo} />
        <Hidden smDown>
          <Typography variant="h6" color="inherit">
            {user.first_name}
          </Typography>
        </Hidden>
      </Box>
      <Menu
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        keepMounted
        PaperProps={{ className: classes.popover }}
        getContentAnchorEl={null}
        anchorEl={ref.current}
        open={isOpen}
      >
        <MenuItem component={RouterLink} to={profileRoute}>
          <PersonOutlineOutlinedIcon style={{ marginRight: "12.5px" }} /> Profile
        </MenuItem>
        {!staff?.is_brand_approved && staff?.brand && (
          <MenuItem component={RouterLink} to={getBrandDetailsRoute()}>
            <BrandingWatermarkOutlinedIcon style={{ marginRight: "12.5px" }} /> My Brand
          </MenuItem>
        )}
        <MenuItem component={RouterLink} to={subscribeRoute}>
          <CameraAltOutlinedIcon style={{ marginRight: "12.5px" }} /> Scan To Subscribe
        </MenuItem>
        <MenuItem component={RouterLink} to={joinQRRoute} onClick={handleClose}>
          <CropFreeIcon style={{ marginRight: "12.5px" }} /> Join QR Code
        </MenuItem>
        <MenuItem component={RouterLink} to={privacyRoute}>
          <LockIcon style={{ marginRight: "12.5px" }} />
          Privacy Policy
        </MenuItem>
        <MenuItem onClick={handleLogout} className={classes.logOut}>
          <ExitToAppOutlinedIcon style={{ marginRight: "12.5px" }} />
          Logout
        </MenuItem>
      </Menu>
    </>
  )
}

export default Account
