import React from "react"

export const AsteriskRating = ({ score }) => {
  return (
    <svg width="179" height="33" viewBox="0 0 179 33" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.4685 1.38135V11.9329L2.44701 8.34175L0 15.6057L10.1733 19.1245L3.74871 27.4579L9.94328 32.1253L16.139 23.7115L22.5612 32.5071L28.9122 27.8431L22.5612 19.1245L32.5827 15.9139L30.2863 8.64648L20.2672 11.9329V1.38135H12.4685Z"
        fill={score > 0 ? `#0052D4` : `#E0E0E0`}
        // stroke={score > 0 ? `` : `white`}
        strokeWidth="2px"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M49.0728 1.38135V11.9329L39.0513 8.34175L36.6043 15.6057L46.7776 19.1245L40.353 27.4579L46.5476 32.1253L52.7433 23.7115L59.1656 32.5071L65.5165 27.8431L59.1656 19.1245L69.187 15.9139L66.8907 8.64648L56.8715 11.9329V1.38135H49.0728Z"
        fill={score > 1 ? `#0052D4` : `#E0E0E0`}
        // stroke={score > 1 ? `` : `white`}
        strokeWidth="2px"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M85.6771 1.38135V11.9329L75.6556 8.34175L73.2086 15.6057L83.3819 19.1245L76.9573 27.4579L83.1519 32.1253L89.3476 23.7115L95.7699 32.5071L102.121 27.8431L95.7699 19.1245L105.791 15.9139L103.495 8.64648L93.4758 11.9329V1.38135H85.6771Z"
        fill={score > 2 ? `#0052D4` : `#E0E0E0`}
        // stroke={score > 2 ? `` : `white`}
        strokeWidth="2px"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M122.281 1.38135V11.9329L112.26 8.34175L109.813 15.6057L119.986 19.1245L113.562 27.4579L119.756 32.1253L125.952 23.7115L132.374 32.5071L138.725 27.8431L132.374 19.1245L142.396 15.9139L140.099 8.64648L130.08 11.9329V1.38135H122.281Z"
        fill={score > 3 ? `#0052D4` : `#E0E0E0`}
        // stroke={score > 3 ? `` : `white`}
        strokeWidth="2px"
      />
      <g clipPath="url(#clip0)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M158.195 0.690918V11.2425L148.174 7.65133L145.727 14.9153L155.9 18.434L149.475 26.7675L155.67 31.4349L161.866 23.021L168.288 31.8167L174.639 27.1527L168.288 18.434L178.309 15.2235L176.013 7.95606L165.994 11.2425V0.690918H158.195Z"
          fill={score > 4 ? `#0052D4` : `#E0E0E0`}
          // stroke={score > 4 ? `` : `white`}
          strokeWidth="2px"
        />
      </g>
      <defs>
        <linearGradient id="paint0_linear" x1="0" y1="16.7653" x2="142.396" y2="16.7654" gradientUnits="userSpaceOnUse">
          <stop stopColor="#70CDDD" />
          <stop offset="1" stopColor="#662D91" />
        </linearGradient>
        <linearGradient id="paint1_linear" x1="0" y1="16.7653" x2="142.396" y2="16.7654" gradientUnits="userSpaceOnUse">
          <stop stopColor="#70CDDD" />
          <stop offset="1" stopColor="#662D91" />
        </linearGradient>
        <linearGradient id="paint2_linear" x1="0" y1="16.7653" x2="142.396" y2="16.7654" gradientUnits="userSpaceOnUse">
          <stop stopColor="#70CDDD" />
          <stop offset="1" stopColor="#662D91" />
        </linearGradient>
        <linearGradient id="paint3_linear" x1="0" y1="16.7653" x2="142.396" y2="16.7654" gradientUnits="userSpaceOnUse">
          <stop stopColor="#70CDDD" />
          <stop offset="1" stopColor="#662D91" />
        </linearGradient>
        <clipPath id="clip0">
          <rect width="33.151" height="31.7698" fill="white" transform="translate(145.849)" />
        </clipPath>
      </defs>
    </svg>
  )
}
