import {
  ADD_TEMPLATE,
  UPDATE_TEMPLATE,
  DELETE_TEMPLATE,
  FETCH_TEMPLATES,
  FETCH_TEMPLATE,
  SELECT_TEMPLATE,
} from "./types"

export const addTemplateAction = (payload) => (dispatch) => {
  dispatch({ type: ADD_TEMPLATE, payload: payload })
}

export const updateTemplateAction = (payload) => (dispatch) => {
  dispatch({ type: UPDATE_TEMPLATE, payload: payload })
}

export const deleteTemplateAction = (payload) => (dispatch) => {
  dispatch({ type: DELETE_TEMPLATE, payload: payload })
}

export const fetchTemplatesAction = (data) => (dispatch) => {
  dispatch({ type: FETCH_TEMPLATES, payload: data })
}

export const getTemplateAction = (data) => (dispatch) => {
  dispatch({ type: FETCH_TEMPLATE, payload: data })
}

export const selectTemplateAction = (template) => (dispatch) => {
  dispatch({ type: SELECT_TEMPLATE, payload: template })
}
