import React from "react"
import { useField } from "formik"
import { Box, MenuItem, TextField, Typography } from "@material-ui/core"
import { makeStyles } from "@material-ui/styles"

const useStyles = makeStyles({
  root: {
    borderRadius: "7px",
    color: "#79869F",
    fontSize: "16px",
    border: "1px solid #79869F",
    "&:hover": {
      border: "1px solid black",
      "& .MuiOutlinedInput-notchedOutline": {
        border: "none",
      },
    },
    "&.Mui-focused": {
      border: "1px solid #5850EC",
      "& .MuiOutlinedInput-notchedOutline": {
        border: "none",
      },
    },
    "& .MuiOutlinedInput-notchedOutline": {
      border: "none",
    },
  },
  title: {
    fontWeight: "bold",
    fontSize: "16px",
    marginBottom: "10px",
  },
})

export const SelectField = ({ ...props }) => {
  const classes = useStyles()
  const [field, { touched, error }] = useField(props)
  const { titleClass, title, options, optionKey, optionValue, optionName, divider } = props
  return (
    <>
      {title && <Typography className={titleClass || classes.title}>{title}</Typography>}
      <TextField
        select
        {...field}
        {...props}
        InputProps={{ className: classes.root }}
        error={touched && error ? error : null}
        helperText={touched && error}
      >
        {options.map((option) => (
          <MenuItem key={option[`${optionKey}`]} value={option[`${optionValue}`]}>
            {option[`${optionName}`]}
          </MenuItem>
        ))}
      </TextField>
      {divider && <Box borderBottom="1px solid #C2C6D2" mt={4} />}
    </>
  )
}
