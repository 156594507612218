import { BASE_URL } from "src/Config"
import axiosInstance from "./axios"

export const fetchInboxMessages = (page) => {
  return axiosInstance.get(`${BASE_URL}/api/inbox/messages/?page=${page}`)
}

export const fetchUnreadCount = () => {
  return axiosInstance.get(`${BASE_URL}/api/inbox/messages/unread_count/`)
}

export const unreadMessage = (id) => {
  return axiosInstance.post(`${BASE_URL}/api/inbox/messages/${id}/unread/`)
}

export const readMessage = (id) => {
  return axiosInstance.put(`${BASE_URL}/api/inbox/messages/${id}/read/`)
}
