import { BASE_URL } from "src/Config"
import { getCurrentBrandId } from "src/utils/auth"
import axiosInstance from "./axios"

export const fetchRegions = () => {
  const brand = getCurrentBrandId()
  return axiosInstance.get(`${BASE_URL}/api/dashboards/${brand}/regions/`)
}

export const fetchRegion = (regionId) => {
  const brand = getCurrentBrandId()
  return axiosInstance.get(`${BASE_URL}/api/dashboards/${brand}/regions/${regionId}/`)
}

export const addRegion = (region) => {
  const brand = getCurrentBrandId()
  return axiosInstance.post(`${BASE_URL}/api/dashboards/${brand}/regions/`, region)
}

export const updateRegion = (regionId, region) => {
  const brand = getCurrentBrandId()
  return axiosInstance.patch(`${BASE_URL}/api/dashboards/${brand}/regions/${regionId}/`, region)
}

export const deleteRegion = (regionId) => {
  const brand = getCurrentBrandId()
  return axiosInstance.delete(`${BASE_URL}/api/dashboards/${brand}/regions/${regionId}/`)
}
