import React, { useState } from "react"
import { NavLink as RouterLink } from "react-router-dom"
import { makeStyles } from "@material-ui/styles"
import { ListItem, Button, Collapse, Box, Typography } from "@material-ui/core"
import clsx from "clsx"
import PropTypes from "prop-types"
import ExpandMoreIcon from "@material-ui/icons/ExpandMore"
import ExpandLessIcon from "@material-ui/icons/ExpandLess"

const useStyles = makeStyles((theme) => ({
  item: {
    display: "block",
    paddingTop: 0,
    paddingBottom: 0,
    color: "#546E7A",
  },
  itemLeaf: {
    display: "flex",
    justifyContent: "center",
    paddingTop: 0,
    paddingBottom: 0,
  },
  button: {
    padding: "10px 8px",
    justifyContent: "flex-start",
    textTransform: "capitalize",
    letterSpacing: 0,
    width: "100%",
    color: "#546E7A",
    borderRadius: 0,
    "&:hover": {
      color: "#662D91",
      fontWeight: "500",
      "& $icon": {
        color: "#2F80ED",
      },
    },
    "&:focus": {
      color: "#662D91",
      fontWeight: "500",
      backgroundColor: "#F4F6F8",
      borderLeft: "2px solid #2F80ED",
      "& $icon": {
        color: "#2F80ED",
      },
    },
  },
  buttonLeaf: {
    marginTop: "10px",
    padding: "15px",
    justifyContent: "space-evenly",
    textTransform: "capitalize",
    borderRadius: "10px",
    letterSpacing: 0,
    width: "100%",
    fontWeight: "500",
    "&.depth-0": {
      fontWeight: theme.typography.fontWeightMedium,
    },
    color: "#546E7A",
    "&:hover": {
      color: "#662D91",
      fontWeight: "500",
      "& $icon": {
        color: "#2F80ED",
      },
    },
  },
  icon: {
    color: theme.palette.icon,
    display: "flex",
    alignItems: "center",
    marginRight: theme.spacing(1),
  },
  expandIcon: {
    marginLeft: "auto",
    height: 16,
    width: 16,
  },
  label: {
    display: "flex",
    alignItems: "center",
    marginLeft: "auto",
  },
  active: {
    color: "white",
    backgroundColor: "#0052D4",
    fontWeight: "500",
    // marginLeft: '-15px',
    "& $icon": {
      color: "white",
    },
    "&:hover": {
      backgroundColor: "#0052D4",
      color: "white",
    },
  },
}))

function NavItem({ title, href, depth, children, icon: Icon, className, open: openProp, label: Label, ...rest }) {
  const classes = useStyles()
  const [open, setOpen] = useState(openProp)

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen)
  }

  let paddingLeft = 8

  if (depth > 0) {
    paddingLeft = 32 + 8 * depth
  }

  const style = {
    paddingLeft,
  }

  if (children) {
    return (
      <ListItem {...rest} className={clsx(classes.item, className)} disableGutters key={title}>
        <Button
          className={classes.button}
          activeClassName={classes.active}
          onClick={handleToggle}
          style={style}
          startIcon={Icon && <Icon className={classes.icon} />}
        >
          {title}
          {open ? (
            <ExpandLessIcon className={classes.expandIcon} color="inherit" />
          ) : (
            <ExpandMoreIcon className={classes.expandIcon} color="inherit" />
          )}
        </Button>
        <Collapse in={open}>{children}</Collapse>
      </ListItem>
    )
  }

  return (
    <ListItem {...rest} className={clsx(classes.itemLeaf, className)} disableGutters key={title}>
      <Button
        activeClassName={classes.active}
        className={clsx(classes.buttonLeaf, `depth-${depth}`)}
        component={RouterLink}
        exact
        style={style}
        to={href}
        // startIcon={Icon && <Icon />}
      >
        <Box width="30%" display="flex" justifyContent="center" alignItems="center">
          {Icon && <Icon />}
        </Box>
        <Box width="70%">{title}</Box>
        {Label && (
          <Typography className={classes.label}>
            <Label />
          </Typography>
        )}
      </Button>
    </ListItem>
  )
}

NavItem.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  depth: PropTypes.number.isRequired,
  href: PropTypes.string,
  icon: PropTypes.any,
  label: PropTypes.any,
  open: PropTypes.bool,
  title: PropTypes.string.isRequired,
}

NavItem.defaultProps = {
  open: false,
}

export default NavItem
