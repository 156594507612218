import {
  DELETE_SURVEY,
  FETCH_SURVEYS_LIST,
  FETCH_SURVEY_ANALYTICS,
  FETCH_SURVEY_FOLDER,
  DELETE_SURVEY_FOLDER,
  UPDATE_SURVEY_FOLDER,
  OPEN_FOLDER,
  UPDATE_OPENED_FOLDER,
  FETCH_FOLDERS_PERFORMANCE_TREND,
  FETCH_FOLDERS_RESPONSE_TREND,
  FETCH_FOLDERS_NPS_TREND,
  FETCH_FOLDERS_SATISFACTION_ANALYTICS,
  FETCH_FOLDERS_NPS_ANALYTICS,
  FETCH_FOLDERS_REVIEWS_COUNT,
  FETCH_FOLDERS_SHORTLIST,
} from "./types"

export const fetchSurveysAction = (data) => (dispatch) => {
  dispatch({ type: FETCH_SURVEYS_LIST, payload: data })
}

export const retrieveSurveyAnalyticsAction = (data) => (dispatch) => {
  dispatch({ type: FETCH_SURVEY_ANALYTICS, payload: data })
}

export const deleteSurveyAction = (payload) => (dispatch) => {
  dispatch({ type: DELETE_SURVEY, payload })
}

// Survey Folders

export const fetchFolderAction = (data) => (dispatch) => {
  dispatch({ type: FETCH_SURVEY_FOLDER, payload: data })
}

export const deleteFolderAction = (payload) => (dispatch) => {
  dispatch({ type: DELETE_SURVEY_FOLDER, payload })
}

export const updateFolderAction = (payload) => (dispatch) => {
  dispatch({ type: UPDATE_SURVEY_FOLDER, payload: payload })
}

export const openFolderAction = (data) => (dispatch) => {
  dispatch({ type: OPEN_FOLDER, payload: data })
}

export const updateOpenedFolderAction = (data) => (dispatch) => {
  dispatch({ type: UPDATE_OPENED_FOLDER, payload: data })
}

export const fetchFoldersPerformanceTrendAction = (data) => (dispatch) => {
  dispatch({ type: FETCH_FOLDERS_PERFORMANCE_TREND, payload: data })
}

export const fetchFoldersResponseTrendAction = (data) => (dispatch) => {
  dispatch({ type: FETCH_FOLDERS_RESPONSE_TREND, payload: data })
}

export const fetchFoldersNPSTrendAction = (data) => (dispatch) => {
  dispatch({ type: FETCH_FOLDERS_NPS_TREND, payload: data })
}

export const fetchFoldersSatisfactionAnalyticsAction = (data) => (dispatch) => {
  dispatch({ type: FETCH_FOLDERS_SATISFACTION_ANALYTICS, payload: data })
}

export const fetchFoldersNPSAnalyticsAction = (data) => (dispatch) => {
  dispatch({ type: FETCH_FOLDERS_NPS_ANALYTICS, payload: data })
}

export const fetchFoldersReviewsCountAction = (data) => (dispatch) => {
  dispatch({ type: FETCH_FOLDERS_REVIEWS_COUNT, payload: data })
}

export const fetchFoldersShortlistAction = (data) => (dispatch) => {
  dispatch({ type: FETCH_FOLDERS_SHORTLIST, payload: data })
}
