import {
  CREATE_DYNAMIC_MENU,
  DELETE_DYNAMIC_MENU,
  FETCH_DYNAMIC_MENUS,
  SELECT_DYNAMIC_MENU,
  CREATE_MENU_GROUP,
  DELETE_MENU_GROUP,
  UPDATE_DYNAMIC_MENU,
  FETCH_MENU_ITEMS,
  SELECT_MENU_ITEM,
  CREATE_MENU_ITEM,
  UPDATE_MENU_ITEM,
  DELETE_MENU_ITEM,
  SET_MODAL_STATUS,
  UPDATE_MENU_GROUPS,
} from "./types"

export const fetchDynamicMenusAction = (data) => (dispatch) => {
  dispatch({ type: FETCH_DYNAMIC_MENUS, payload: data })
}

export const selectDynamicMenuAction = (data) => (dispatch) => {
  dispatch({ type: SELECT_DYNAMIC_MENU, payload: data })
}

export const createDynamicMenuAction = (data) => (dispatch) => {
  dispatch({ type: CREATE_DYNAMIC_MENU, payload: data })
}

export const updateDynamicMenuAction = (data) => (dispatch) => {
  dispatch({ type: UPDATE_DYNAMIC_MENU, payload: data })
}

export const deleteDynamicMenuAction = (data) => (dispatch) => {
  dispatch({ type: DELETE_DYNAMIC_MENU, payload: data })
}

export const createMenuGroupAction = (data) => (dispatch) => {
  dispatch({ type: CREATE_MENU_GROUP, payload: data })
}

export const updateMenuGroupsAction = (data) => (dispatch) => {
  dispatch({ type: UPDATE_MENU_GROUPS, payload: data })
}

export const deleteMenuGroupAction = (data) => (dispatch) => {
  dispatch({ type: DELETE_MENU_GROUP, payload: data })
}

export const fetchMenuItemsAction = (data) => (dispatch) => {
  dispatch({ type: FETCH_MENU_ITEMS, payload: data })
}

export const selectMenuItemAction = (data) => (dispatch) => {
  dispatch({ type: SELECT_MENU_ITEM, payload: data })
}

export const createMenuItemAction = (data) => (dispatch) => {
  dispatch({ type: CREATE_MENU_ITEM, payload: data })
}

export const updateMenuItemAction = (data) => (dispatch, getState) => {
  const updatedItems = getState().menus.items.results.map((item) => {
    if (item.id === data.id) return data
    return item
  })
  dispatch({ type: UPDATE_MENU_ITEM, payload: updatedItems })
}

export const deleteMenuItemAction = (data) => (dispatch) => {
  dispatch({ type: DELETE_MENU_ITEM, payload: data })
}

export const setMenuModalStatusAction = (data) => (dispatch) => {
  dispatch({ type: SET_MODAL_STATUS, payload: data })
}
