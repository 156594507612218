import React, { lazy } from "react"
import { Redirect } from "react-router-dom"
import BranchLayout from "src/views/Branch/Details"
import AuthLayout from "./layouts/Auth"
import ErrorLayout from "./layouts/Error"
import WelcomeLayout from "./layouts/Welcome"
import DashboardLayout from "./layouts/Dashboard"
import {
  branchDashboardRoute,
  dashboardRoute,
  confirmEmailRoute,
  confirmResetPasswordRoute,
  emailSentRoute,
  loginRoute,
  messageRoute,
  messageDetailsRoute,
  registrationRoute,
  resetPasswordRoute,
  reviewRoute,
  subscribeRoute,
  welcomeRoute,
  staffRoute,
  staffDetailsRoute,
  cardRoute,
  reviewDetailsRoute,
  customerRoute,
  profileRoute,
  surveyDetailsRoute,
  surveyRoute,
  subscriptionCardRoute,
  notFoundRoute,
  brandRoute,
  brandDetailsRoute,
  managementRoute,
  dashboardBrandDetailsRoute,
  dashboardWelcomeRoute,
  branchRoute,
  cardDetailsRoute,
  dashboardSubscribeRoute,
  dashboardProfileRoute,
  dashboardReviewRoute,
  dashboardReviewDetailsRoute,
  privacyRoute,
  unauthorizedRoute,
  serverErrorRoute,
  // menuRoute,
  // loyaltyRoute,
  // orderRoute,
  surveyAnalyticsRoute,
  joinQRRoute,
  qrCardsRoute,
  urlsRoute,
  urlDetailsRoute,
  surveysFolderRoute,
} from "./constants"

export default [
  {
    path: "/",
    exact: true,
    component: () => <Redirect to={welcomeRoute} />,
  },
  {
    path: "/content",
    component: AuthLayout,
    routes: [
      {
        path: privacyRoute,
        exact: true,
        component: lazy(() => import("src/views/Privacy")),
      },
    ],
  },
  {
    path: "/auth",
    component: AuthLayout,
    routes: [
      {
        path: loginRoute,
        exact: true,
        component: lazy(() => import("src/views/Auth/Login")),
      },
      {
        path: registrationRoute,
        exact: true,
        component: lazy(() => import("src/views/Auth/Register")),
      },
      {
        path: confirmEmailRoute,
        exact: true,
        component: lazy(() => import("src/views/Auth/Register/ConfirmEmail")),
      },
      {
        path: resetPasswordRoute,
        exact: true,
        component: lazy(() => import("src/views/Auth/ForgotPassword")),
      },
      {
        path: confirmResetPasswordRoute,
        exact: true,
        component: lazy(() => import("src/views/Auth/ForgotPassword/ResetPasswordForm")),
      },
      {
        path: emailSentRoute,
        exact: true,
        component: lazy(() => import("src/views/Auth/ForgotPassword/EmailSent")),
      },
      {
        component: () => <Redirect to={notFoundRoute} />,
      },
    ],
  },
  {
    path: "/branches",
    component: BranchLayout,
    routes: [
      {
        path: branchDashboardRoute,
        exact: true,
        component: lazy(() => import("src/views/Dashboard/Brand")),
        roles: ["owner", "manager", "surv"],
      },
      {
        path: staffRoute,
        exact: true,
        component: lazy(() => import("src/views/Staff")),
        roles: ["owner", "manager", "surv"],
      },
      {
        path: staffDetailsRoute,
        exact: true,
        component: lazy(() => import("src/views/Dashboard/Staff")),
        roles: ["owner", "manager", "surv"],
      },
      {
        path: reviewRoute,
        exact: true,
        component: lazy(() => import("src/views/Review/List")),
        roles: ["surv", "owner", "manager"],
      },
      {
        path: reviewDetailsRoute,
        exact: true,
        component: lazy(() => import("src/views/Review/Details")),
        roles: ["surv", "owner", "manager"],
      },
      {
        path: cardRoute,
        exact: true,
        component: lazy(() => import("src/views/QRCard/List")),
        roles: ["owner", "manager", "surv"],
      },
      {
        path: subscriptionCardRoute,
        exact: true,
        component: lazy(() => import("src/views/QRCard/BranchQR")),
        roles: ["owner", "manager", "surv"],
      },
      {
        component: () => <Redirect to={notFoundRoute} />,
        roles: [],
        type: "error",
      },
    ],
  },
  {
    path: "/errors",
    component: ErrorLayout,
    routes: [
      {
        path: unauthorizedRoute,
        exact: true,
        component: lazy(() => import("src/views/Errors/Error401")),
      },
      {
        path: notFoundRoute,
        exact: true,
        component: lazy(() => import("src/views/Errors/Error404")),
      },
      {
        path: serverErrorRoute,
        exact: true,
        component: lazy(() => import("src/views/Errors/Error500")),
      },
      {
        component: () => <Redirect to={notFoundRoute} />,
      },
    ],
  },
  {
    path: "/welcome",
    component: WelcomeLayout,
    routes: [
      {
        path: welcomeRoute,
        exact: true,
        component: lazy(() => import("./views/Welcome")),
        roles: ["owner", "manager", "surv", "default"],
      },
      {
        path: subscribeRoute,
        exact: true,
        component: lazy(() => import("src/views/Subscribe")),
        roles: ["owner", "manager", "surv", "default"],
      },
      {
        path: profileRoute,
        exact: true,
        component: lazy(() => import("src/views/Account/General")),
        roles: ["owner", "manager", "surv", "default"],
      },
    ],
  },
  {
    path: "/venue",
    component: WelcomeLayout,
    routes: [
      {
        path: brandRoute,
        exact: true,
        component: lazy(() => import("./views/Brand/New")),
        roles: ["surv", "default"],
      },
      {
        path: brandDetailsRoute,
        exact: true,
        component: lazy(() => import("./views/Brand/Details")),
        roles: ["owner", "surv"],
      },
      {
        component: () => <Redirect to={notFoundRoute} />,
        roles: [],
        type: "error",
      },
    ],
  },
  {
    path: "/dashboard",
    component: DashboardLayout,
    routes: [
      {
        path: dashboardWelcomeRoute,
        exact: true,
        component: lazy(() => import("./views/Welcome")),
        roles: ["owner", "manager", "surv", "default"],
      },
      {
        path: dashboardBrandDetailsRoute,
        exact: true,
        component: lazy(() => import("src/views/Brand/Details")),
        roles: ["owner", "surv"],
      },
      {
        path: dashboardSubscribeRoute,
        exact: true,
        component: lazy(() => import("src/views/Subscribe")),
        roles: ["owner", "manager", "surv", "default"],
      },
      {
        path: joinQRRoute,
        exact: true,
        component: lazy(() => import("src/views/JoinQR")),
        roles: ["owner", "manager", "surv", "default"],
      },
      {
        path: dashboardRoute,
        exact: true,
        component: lazy(() => import("src/views/Dashboard")),
        roles: ["owner", "surv"],
      },
      // {
      //   path: menuRoute,
      //   exact: true,
      //   component: lazy(() => import('src/views/Menu')),
      //   roles: ['owner', 'surv'],
      // },
      {
        path: qrCardsRoute,
        exact: true,
        component: lazy(() => import("src/views/QrCards")),
        roles: ["owner", "manager", "surv", "default"],
      },
      {
        path: urlsRoute,
        exact: true,
        component: lazy(() => import("src/views/URLs")),
        roles: ["owner", "manager", "surv"],
      },
      {
        path: urlDetailsRoute,
        exact: true,
        component: lazy(() => import("src/views/URLs/Details")),
        roles: ["owner", "manager", "surv"],
      },
      {
        path: cardDetailsRoute,
        exact: true,
        component: lazy(() => import("src/views/QRCard/Details")),
        roles: ["owner", "manager", "surv"],
      },
      {
        path: managementRoute,
        exact: true,
        component: lazy(() => import("./views/Management")),
        roles: ["owner", "surv"],
      },
      {
        path: messageRoute,
        exact: true,
        component: lazy(() => import("src/views/Message")),
        roles: ["owner", "manager", "surv"],
      },
      {
        path: messageDetailsRoute,
        exact: true,
        component: lazy(() => import("src/views/Message/MessageDetails")),
        roles: ["owner", "manager", "surv"],
      },
      {
        path: customerRoute,
        exact: true,
        component: lazy(() => import("src/views/Customer/CustomerList")),
        roles: ["surv", "owner"],
      },
      {
        path: dashboardProfileRoute,
        exact: true,
        component: lazy(() => import("src/views/Account/General")),
        roles: ["surv", "owner", "manager", "default"],
      },
      {
        path: surveyDetailsRoute,
        exact: true,
        component: lazy(() => import("src/views/Survey/Details")),
        roles: ["surv", "owner"],
      },
      {
        path: surveysFolderRoute,
        exact: true,
        component: lazy(() => import("src/views/Survey/Folders/FolderSurveys")),
        roles: ["surv", "owner"],
      },
      {
        path: surveyRoute,
        exact: true,
        component: lazy(() => import("src/views/Survey")),
        roles: ["surv", "owner"],
      },
      {
        path: surveyAnalyticsRoute,
        exact: true,
        component: lazy(() => import("src/views/Survey/Analytics")),
        roles: ["surv", "owner"],
      },
      {
        path: branchRoute,
        exact: true,
        component: lazy(() => import("src/views/Branch/Results")),
        roles: ["surv", "owner", "manager"],
      },
      {
        path: dashboardReviewRoute,
        exact: true,
        component: lazy(() => import("src/views/Review/List")),
        roles: ["surv", "owner", "manager"],
      },
      {
        path: dashboardReviewDetailsRoute,
        exact: true,
        component: lazy(() => import("src/views/Review/Details")),
        roles: ["surv", "owner", "manager"],
      },
      // {
      //   path: loyaltyRoute,
      //   exact: true,
      //   component: lazy(() => import('src/views/Loyalty')),
      //   roles: ['surv', 'owner', 'manager'],
      // },
      // {
      //   path: orderRoute,
      //   exact: true,
      //   component: lazy(() => import('src/views/Order')),
      //   roles: ['surv', 'owner', 'manager'],
      // },
      {
        component: () => <Redirect to={notFoundRoute} />,
        roles: [],
        type: "error",
      },
    ],
  },
  {
    route: "*",
    component: () => <Redirect to={notFoundRoute} />,
  },
]
