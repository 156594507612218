import React from "react"
import { Link as RouterLink } from "react-router-dom"
import PropTypes from "prop-types"
import clsx from "clsx"
import { makeStyles } from "@material-ui/styles"
import { AppBar, Toolbar, colors, Box } from "@material-ui/core"
import { SurvLogoIcon } from "src/components/Icons/SurvLogo"
import { welcomeRoute } from "src/constants"
import Account from "../Common/Account"

const useStyles = makeStyles((theme) => ({
  root: {
    boxShadow: "none",
  },
  flexGrow: {
    flexGrow: 1,
  },
  search: {
    backgroundColor: "rgba(255,255,255, 0.1)",
    borderRadius: 4,
    flexBasis: 300,
    height: 36,
    padding: theme.spacing(0, 2),
    display: "flex",
    alignItems: "center",
  },
  searchIcon: {
    marginRight: theme.spacing(2),
    color: "inherit",
  },
  searchInput: {
    flexGrow: 1,
    color: "inherit",
    "& input::placeholder": {
      opacity: 1,
      color: "inherit",
    },
  },
  searchPopper: {
    zIndex: theme.zIndex.appBar + 100,
  },
  searchPopperContent: {
    marginTop: theme.spacing(1),
  },
  trialButton: {
    marginLeft: theme.spacing(2),
    color: theme.palette.common.white,
    backgroundColor: colors.green[600],
    "&:hover": {
      backgroundColor: colors.green[900],
    },
  },
  trialIcon: {
    marginRight: theme.spacing(1),
  },
  menuButton: {
    marginRight: theme.spacing(1),
  },
  chatButton: {
    marginLeft: theme.spacing(1),
  },
  notificationsButton: {
    marginLeft: theme.spacing(1),
  },
  notificationsBadge: {
    backgroundColor: colors.orange[600],
  },
  logoutButton: {
    marginLeft: theme.spacing(1),
  },
  logoutIcon: {
    marginRight: theme.spacing(1),
  },
  span: {
    backgroundColor: "red",
    borderRadius: "50%",
    fontSize: "12px",
    padding: "1px 3px",
  },
  msgButton: {
    display: "flex",
    marginRight: "3%",
    marginTop: "0.5%",
    cursor: "pointer",
  },
  logo: {
    height: "56px",
    width: "117px",
    padding: "2px",
  },
}))

function TopBar({ onOpenNavBarMobile, className, data, role, staff, messagesCount, ...rest }) {
  const classes = useStyles()

  return (
    <AppBar {...rest} className={clsx(classes.root, className)} color="primary">
      <Toolbar>
        <RouterLink to={welcomeRoute}>
          <SurvLogoIcon />
        </RouterLink>
        <div className={classes.flexGrow} />
        <Box ml={2}>
          <Account staff={staff} />
        </Box>
      </Toolbar>
    </AppBar>
  )
}

TopBar.propTypes = {
  className: PropTypes.string,
  onOpenNavBarMobile: PropTypes.func,
}

export default TopBar
