import React from "react"

export const AddIcon = () => {
  return (
    <svg width="20" height="20" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M9.4614 26H3.639C1.60116 26 0 24.4541 0 22.4865V3.51351C0 1.54595 1.60116 0 3.639 0H9.4614C10.262 0 10.917 0.632432 10.917 1.40541C10.917 2.17838 10.262 2.81081 9.4614 2.81081H3.639C3.20232 2.81081 2.9112 3.09189 2.9112 3.51351V22.4865C2.9112 22.9081 3.20232 23.1892 3.639 23.1892H9.4614C10.262 23.1892 10.917 23.8216 10.917 24.5946C10.917 25.3676 10.262 26 9.4614 26Z"
        fill="white"
      />
      <path
        d="M16.4483 0H22.2707C24.3085 0 25.9097 1.54595 25.9097 3.51351V22.4865C25.9097 24.4541 24.3085 26 22.2707 26H16.4483C15.6477 26 14.9927 25.3676 14.9927 24.5946C14.9927 23.8216 15.6477 23.1892 16.4483 23.1892H22.2707C22.7074 23.1892 22.9985 22.9081 22.9985 22.4865V3.51351C22.9985 3.09189 22.7074 2.81081 22.2707 2.81081H16.4483C15.6477 2.81081 14.9927 2.17838 14.9927 1.40541C14.9927 0.632432 15.6477 0 16.4483 0Z"
        fill="white"
      />
      <path
        d="M19.3595 13C19.3595 13.773 18.7045 14.4054 17.9039 14.4054H14.6288V17.7784C14.6288 18.5514 13.9738 19.1838 13.1732 19.1838C12.3726 19.1838 11.7176 18.5514 11.7176 17.7784V14.4054H8.22415C7.42357 14.4054 6.76855 13.773 6.76855 13C6.76855 12.227 7.42357 11.5946 8.22415 11.5946H11.7176V8.22163C11.7176 7.44866 12.3726 6.81622 13.1732 6.81622C13.9738 6.81622 14.6288 7.44866 14.6288 8.22163V11.5946H17.9039C18.7045 11.5946 19.3595 12.227 19.3595 13Z"
        fill="white"
      />
    </svg>
  )
}
