import React from "react"
import { SelectField } from "./SelectField"
import { SwitchField } from "./SwitchField"
import { TagsField } from "./TagsField"
import { SingleField } from "./TextField"

export const Field = ({ ...props }) => {
  const { options, tags, switcher } = props
  if (options) return <SelectField {...props} />
  if (tags) return <TagsField {...props} />
  if (switcher) return <SwitchField {...props} />
  return <SingleField {...props} />
}
