import {
  GET_MEMBER_DASHBOARD,
  FETCH_STAFF_RESPONSE_TREND,
  FETCH_STAFF_PERFORMANCE_TREND,
  FETCH_STAFF_NPS_TREND,
} from "src/store/actions/types"

const INITIAL_STATE = {
  score: -1,
  customer_satisfaction: null,
  success_factors_score: [],
  staff_data: null,
  performanceTrend: [],
  responseTrend: [],
  npsTrend: [],
}

export default (state = INITIAL_STATE, { type, payload }) => {
  switch (type) {
    case GET_MEMBER_DASHBOARD:
      return { ...state, ...payload }
    case FETCH_STAFF_PERFORMANCE_TREND:
      return { ...state, performanceTrend: payload }
    case FETCH_STAFF_RESPONSE_TREND:
      return { ...state, responseTrend: payload }
    case FETCH_STAFF_NPS_TREND:
      return { ...state, npsTrend: payload }
    default:
      return state
  }
}
