import { SET_FROM_DATE, SET_TO_DATE } from "./types"

export const setFromDate = (from) => ({
  type: SET_FROM_DATE,
  payload: from.format("YYYY-MM-DD"),
})

export const setToDate = (to) => ({
  type: SET_TO_DATE,
  payload: to.format("YYYY-MM-DD"),
})
