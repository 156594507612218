import React from "react"
import { useField, useFormikContext } from "formik"
import { Box, FormControlLabel, FormGroup, Switch, Typography } from "@material-ui/core"
import { makeStyles } from "@material-ui/styles"

const useStyles = makeStyles({
  root: {
    borderRadius: "7px",
    color: "#79869F",
    fontSize: "16px",
    border: "1px solid #79869F",
    "&:hover": {
      border: "1px solid black",
      "& .MuiOutlinedInput-notchedOutline": {
        border: "none",
      },
    },
    "&.Mui-focused": {
      border: "1px solid #5850EC",
      "& .MuiOutlinedInput-notchedOutline": {
        border: "none",
      },
    },
    "& .MuiOutlinedInput-notchedOutline": {
      border: "none",
    },
  },
  title: {
    fontWeight: "bold",
    fontSize: "16px",
    marginBottom: "10px",
  },
})

export const SwitchField = ({ ...props }) => {
  const classes = useStyles()
  const [field, { touched, error }] = useField(props)
  const { titleClass, title, label, divider, name } = props
  const { values, setFieldValue } = useFormikContext()

  const handleChange = () => {
    setFieldValue(name, !values[name])
  }

  return (
    <>
      {title && <Typography className={titleClass || classes.title}>{title}</Typography>}
      <FormGroup row>
        <FormControlLabel
          control={
            <Switch
              {...field}
              {...props}
              error={touched && error ? error : null}
              helperText={touched && error}
              onClick={handleChange}
              checked={values[name]}
            />
          }
          label={label}
        />
      </FormGroup>
      {divider && <Box borderBottom="1px solid #C2C6D2" mt={4} />}
    </>
  )
}
