import { BASE_URL } from "src/Config"
import { getCurrentBrandId } from "src/utils/auth"
import axiosInstance from "./axios"

export const fetchDynamicMenus = () => {
  const brand = getCurrentBrandId()
  return axiosInstance.get(`${BASE_URL}/api/dashboards/${brand}/menuv2/`)
}

export const retrieveDynamicMenu = (id) => {
  const brand = getCurrentBrandId()
  return axiosInstance.get(`${BASE_URL}/api/dashboards/${brand}/menuv2/${id}`)
}

export const createDynamicMenu = (menu) => {
  const brand = getCurrentBrandId()
  return axiosInstance.post(`${BASE_URL}/api/dashboards/${brand}/menuv2/`, menu)
}

export const updateDynamicMenu = ({ uuid, menu }) => {
  const brand = getCurrentBrandId()
  return axiosInstance.put(`${BASE_URL}/api/dashboards/${brand}/menuv2/${uuid}/`, menu)
}

export const deleteDynamicMenu = (id) => {
  const brand = getCurrentBrandId()
  return axiosInstance.delete(`${BASE_URL}/api/dashboards/${brand}/menuv2/${id}`)
}

export const fetchMenuItems = (page = 1) => {
  const brand = getCurrentBrandId()
  return axiosInstance.get(`${BASE_URL}/api/dashboards/${brand}/menuv2-items/?page=${page}`)
}

export const retrieveMenuItem = (id) => {
  const brand = getCurrentBrandId()
  return axiosInstance.get(`${BASE_URL}/api/dashboards/${brand}/menuv2-items/${id}`)
}

export const createMenuItem = (item) => {
  const brand = getCurrentBrandId()
  return axiosInstance.post(`${BASE_URL}/api/dashboards/${brand}/menuv2-items/`, item)
}

export const updateMenuItem = ({ item, id }) => {
  const brand = getCurrentBrandId()
  return axiosInstance.patch(`${BASE_URL}/api/dashboards/${brand}/menuv2-items/${id}/`, item)
}

export const deleteMenuItem = (id) => {
  const brand = getCurrentBrandId()
  return axiosInstance.delete(`${BASE_URL}/api/dashboards/${brand}/menuv2-items/${id}`)
}

export const fetchMenuGroups = (uuid, searchQuery = "") => {
  const brand = getCurrentBrandId()
  return axiosInstance.get(`${BASE_URL}/api/dashboards/${brand}/${uuid}/menuv2-groups/?search=${searchQuery}`)
}

export const retrieveMenuGroup = ({ uuid, id }) => {
  const brand = getCurrentBrandId()
  return axiosInstance.get(`${BASE_URL}/api/dashboards/${brand}/${uuid}/menuv2-groups/${id}`)
}

export const createMenuGroup = ({ group, uuid }) => {
  const brand = getCurrentBrandId()
  return axiosInstance.post(`${BASE_URL}/api/dashboards/${brand}/${uuid}/menuv2-groups/`, group)
}

export const updateMenuGroup = ({ group, uuid, id }) => {
  const brand = getCurrentBrandId()
  return axiosInstance.patch(`${BASE_URL}/api/dashboards/${brand}/${uuid}/menuv2-groups/${id}/`, group)
}

export const deleteMenuGroup = ({ uuid, id }) => {
  const brand = getCurrentBrandId()
  return axiosInstance.delete(`${BASE_URL}/api/dashboards/${brand}/${uuid}/menuv2-groups/${id}`)
}

export const addGroupItem = (id, items) => {
  const brand = getCurrentBrandId()
  return axiosInstance.put(`${BASE_URL}/api/dashboards/${brand}/menu-group/${id}/add-item/`, items)
}

export const deleteGroupItem = (id, itemId) => {
  const brand = getCurrentBrandId()
  return axiosInstance.put(`${BASE_URL}/api/dashboards/${brand}/menu-group/${id}/remove-item/${itemId}/`)
}
