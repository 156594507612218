import { CHANGE_REVIEWS_PAGE, CHANGE_REVIEWS_STATUS, FETCH_REVIEWS_LIST } from "../actions/types"

const INITIAL_STATE = { reviews: [], page: 0, status: "current" }

export default (state = INITIAL_STATE, { type, payload }) => {
  switch (type) {
    case FETCH_REVIEWS_LIST:
      return { ...state, reviews: payload }
    case CHANGE_REVIEWS_PAGE:
      return { ...state, reviews: [], page: payload }
    case CHANGE_REVIEWS_STATUS:
      return { ...state, reviews: [], page: 0, status: payload }
    default:
      return state
  }
}
