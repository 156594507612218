import React, { useEffect } from "react"
import { useSelector } from "react-redux"
import { useHistory, useLocation } from "react-router"
import PropTypes from "prop-types"
import { logoutUser } from "src/store/actions"
import { loginRoute } from "src/constants"

function AuthGuard({ children }) {
  const history = useHistory()
  const location = useLocation()
  const session = useSelector((state) => state.session)
  const roles = ["owner", "manager", "surv", "default"]

  useEffect(() => {
    if (!session.loggedIn || !session.user) {
      history.push({ pathname: loginRoute, state: { from: location.pathname } })
      return
    }

    if (!roles.includes(session.user.role)) {
      logoutUser()
    }
  })

  return <>{children}</>
}

AuthGuard.propTypes = {
  children: PropTypes.node,
}

export default AuthGuard
