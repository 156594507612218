import { CLEAR_FILTERS, SET_FILTERS } from "src/store/actions/types"

const INITIAL_STATE = {
  filters: window.location.search,
}

export default (state = INITIAL_STATE, { type, payload }) => {
  switch (type) {
    case SET_FILTERS:
      return { ...state, filters: payload }
    case CLEAR_FILTERS:
      return { ...state, filters: "" }
    default:
      return state
  }
}
