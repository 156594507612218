/* eslint-disable no-underscore-dangle */
/* eslint-disable no-restricted-globals */
/* eslint-disable consistent-return */
import store from "src/store"
import { setPendingAction, openToast, refreshTokenAction, logoutUser } from "src/store/actions"
import {
  createMenuPayload,
  deleteMenuPayload,
  approveStaffPayload,
  deleteStaffPayload,
  deleteCardPayload,
  createCardPayload,
  updateCardPayload,
  updateProfilePayload,
  subscribePayload,
  createSurveyPayload,
  updateSurveyPayload,
  generalErrorPayload,
  createBrandPayload,
  dashboardWelcomeRoute,
  createBranchPayload,
  updateBranchPayload,
  deleteBranchPayload,
  createMenuV2Payload,
  createItemPayload,
  deleteItemPayload,
  addItemsPayload,
  updateItemPayload,
  updateMenuV2Payload,
  updateGroupPayload,
  deleteGroupPayload,
  createGroupPayload,
  createSprintPayload,
  createCouponsPayload,
  updateSprintPayload,
  createOrderPayload,
  updateOrderPayload,
  createRewardPayload,
  deleteSurveyPayload,
  joinQRPayload,
  createAreaPayload,
  updateAreaPayload,
  deleteAreaPayload,
  createRegionPayload,
  updateRegionPayload,
  deleteRegionPayload,
  createTemplatePayload,
  updateTemplatePayload,
  deleteTemplatePayload,
  createShortenedURLPayload,
  updateShortenedURLPayload,
  deleteShortenedURLPayload,
  createFolderPayload,
  updateFolderPayload,
  deleteFolderPayload,
} from "src/constants"
import { refreshToken } from "src/API"
import { setAuthToken } from "./reduxUtils"
import { getRefreshToken, setCurrentUser, setLocalAccessToken } from "./auth"
import {
  branchRoute,
  welcomeRoute,
  unauthorizedRoute,
  loginRoute,
  getQrCardsRoute,
  urlsRoute,
} from "../constants/routes"
import history from "./history"

const { dispatch, getState } = store

export const handleRequestPendingStatus = (config) => {
  const { count } = getState().pending
  const { method } = config
  const createMethods = ["put", "patch", "post"]
  if (method === "get") dispatch(setPendingAction({ fetch: true, count: count + 1 }))
  else if (method === "put" && config.url.includes("remove-item"))
    dispatch(setPendingAction({ delete: true, count: count + 1 }))
  else if (createMethods.includes(method)) dispatch(setPendingAction({ create: true, count: count + 1 }))
  else if (method === "delete") dispatch(setPendingAction({ delete: true, count: count + 1 }))
}

export const handleResponsePendingStatus = () => {
  const { count } = getState().pending

  if (count - 1 === 0)
    dispatch(
      setPendingAction({
        fetch: false,
        create: false,
        delete: false,
        count: count - 1,
      })
    )
  else
    dispatch(
      setPendingAction({
        count: count - 1,
      })
    )
}

export const handleResponse = (response) => {
  const { url, data } = response.config
  if (url.includes("menus")) {
    if (response.status === 201) {
      dispatch(openToast(createMenuPayload.success))
    } else if (response.status === 204) {
      dispatch(openToast(deleteMenuPayload.success))
    }
  } else if (url.includes("staff/approved") || url.includes("staff/pending")) {
    if (response.status === 200 && response?.config?.method === "post") {
      dispatch(openToast(approveStaffPayload.success))
    } else if (response.status === 204) {
      dispatch(openToast(deleteStaffPayload.success))
    }
  } else if (url.includes("qr-codes/bulk")) {
    if (response.status === 201) {
      dispatch(openToast(createCardPayload.success))
    }
  } else if (url.includes("qr-codes")) {
    if (response.status === 201) {
      dispatch(openToast(createCardPayload.success))
      const branchId = JSON.parse(data).location
      history.replace(getQrCardsRoute({ branchId: branchId || "unassigned" }))
    } else if (response.status === 204) {
      dispatch(openToast(deleteCardPayload.success))
    } else if (response.status === 200 && response?.config?.method === "patch") {
      dispatch(openToast(updateCardPayload.success))
      const branchId = JSON.parse(data).location
      history.replace(getQrCardsRoute({ branchId: branchId || "unassigned" }))
    }
  } else if (url.includes("auth/user")) {
    if (response.status === 200 && response?.config?.method === "patch") {
      dispatch(openToast(updateProfilePayload.success))
    }
  } else if (url.includes("password/change")) {
    if (response.status === 200 && response?.config?.method === "post") {
      dispatch(openToast(updateProfilePayload.success))
    }
  } else if (url.includes("venue/staff")) {
    if (response.status === 201) {
      dispatch(openToast(subscribePayload.success))
      history.push("/")
    }
  } else if (
    url.includes("auth/login") ||
    url.includes("auth/registration") ||
    url.includes("auth/google") ||
    url.includes("auth/facebook")
  ) {
    if (response?.config?.method === "post") {
      setCurrentUser(response.data)
      setAuthToken(response.data.access)
      history.push(welcomeRoute)
    }
  } else if (url.includes("auth/register")) {
    if (response.status === 201) {
      dispatch(openToast(subscribePayload.success))
    }
  } else if (url.includes("folders/")) {
    if (response.status === 201) {
      dispatch(openToast(createFolderPayload.success))
    } else if (response.status === 200 && response?.config?.method === "put") {
      dispatch(openToast(updateFolderPayload.success))
    } else if (response.status === 204) {
      dispatch(openToast(deleteFolderPayload.success))
    }
  } else if (url.includes("survey")) {
    if (response.status === 201) {
      dispatch(openToast(createSurveyPayload.success))
      history.goBack()
    } else if (response.status === 200 && response?.config?.method === "put") {
      dispatch(openToast(updateSurveyPayload.success))
      history.goBack()
    } else if (response.status === 204) {
      dispatch(openToast(deleteSurveyPayload.success))
    }
  } else if (url.includes("/brand/create/")) {
    if (response.status === 201) {
      dispatch(openToast(createBrandPayload.success))
      history.push(dashboardWelcomeRoute)
    }
  } else if (url.includes("location/create")) {
    if (response.status === 201) {
      dispatch(openToast(createBranchPayload.success))
    }
  } else if (url.includes("location/")) {
    if (response.status === 200 && response?.config?.method === "patch") {
      dispatch(openToast(updateBranchPayload.success))
    } else if (response.status === 204) {
      dispatch(openToast(deleteBranchPayload.success))
      history.push(branchRoute)
    }
  } else if (url.includes("menuv2/")) {
    if (response.status === 201 && response?.config?.method === "post") {
      dispatch(openToast(createMenuV2Payload.success))
    } else if (response.status === 200 && response?.config?.method === "put") {
      dispatch(openToast(updateMenuV2Payload.success))
    } else if (response.status === 204) {
      dispatch(openToast(deleteMenuPayload.success))
    }
  } else if (url.includes("menuv2-items/")) {
    if (response.status === 201 && response?.config?.method === "post") {
      dispatch(openToast(createItemPayload.success))
    }
    if (response.status === 200 && response?.config?.method === "patch") {
      dispatch(openToast(updateItemPayload.success))
    } else if (response.status === 204) {
      dispatch(openToast(deleteItemPayload.success))
    }
  } else if (url.includes("add-item")) {
    if (response.status === 200 && response?.config?.method === "put") {
      dispatch(openToast(addItemsPayload.success))
    }
  } else if (url.includes("menuv2-groups/")) {
    if (response.status === 200 && response?.config?.method === "patch") {
      dispatch(openToast(updateGroupPayload.success))
    } else if (response.status === 201 && response?.config?.method === "post") {
      dispatch(openToast(createGroupPayload.success))
    } else if (response.status === 204) {
      dispatch(openToast(deleteGroupPayload.success))
    }
  } else if (url.includes("sprints/") && !url.includes("rewards")) {
    if (response.status === 200 && response?.config?.method === "patch") {
      dispatch(openToast(updateSprintPayload.success))
    } else if (response.status === 201 && response?.config?.method === "post") {
      dispatch(openToast(createSprintPayload.success))
    }
  } else if (url.includes("coupons/")) {
    if (response.status === 201 && response?.config?.method === "post") {
      dispatch(openToast(createCouponsPayload.success))
    }
  } else if (url.includes("order/") && response.status === 200 && response?.config?.method === "put") {
    dispatch(openToast(updateOrderPayload.success))
  } else if (url.includes("/order-item/create/") && response.status === 201 && response?.config?.method === "post") {
    dispatch(openToast(createOrderPayload.success))
  } else if (url.includes("sprints/reward")) {
    if (response.status === 201 && response?.config?.method === "post") {
      dispatch(openToast(createRewardPayload.success))
    }
  } else if (url.includes("/qr-requests/request/")) {
    if (response.status === 201 && response?.config?.method === "post") {
      dispatch(openToast(joinQRPayload.success))
      history.push("/")
    }
  } else if (url.includes("areas/")) {
    if (response.status === 201) {
      dispatch(openToast(createAreaPayload.success))
    } else if (response.status === 200 && response?.config?.method === "patch") {
      dispatch(openToast(updateAreaPayload.success))
    } else if (response.status === 204) {
      dispatch(openToast(deleteAreaPayload.success))
    }
  } else if (url.includes("regions/")) {
    if (response.status === 201) {
      dispatch(openToast(createRegionPayload.success))
    } else if (response.status === 200 && response?.config?.method === "patch") {
      dispatch(openToast(updateRegionPayload.success))
    } else if (response.status === 204) {
      dispatch(openToast(deleteRegionPayload.success))
    }
  } else if (url.includes("templates/")) {
    if (response.status === 201) {
      dispatch(openToast(createTemplatePayload.success))
    } else if (response.status === 200 && response?.config?.method === "patch") {
      dispatch(openToast(updateTemplatePayload.success))
    } else if (response.status === 204) {
      dispatch(openToast(deleteTemplatePayload.success))
    }
  } else if (url.includes("urlshortener/")) {
    if (response.status === 201) {
      dispatch(openToast(createShortenedURLPayload.success))
      history.replace(urlsRoute)
    } else if (response.status === 200 && response?.config?.method === "patch") {
      dispatch(openToast(updateShortenedURLPayload.success))
      history.replace(urlsRoute)
    } else if (response.status === 204) {
      dispatch(openToast(deleteShortenedURLPayload.success))
    }
  }
}

export const handleUnAuthorizedError = async (config) => {
  const refresh = getRefreshToken()
  if (refresh) {
    try {
      const originalRequest = config
      const { data } = await refreshToken({ refresh })
      setAuthToken(data.access)
      setLocalAccessToken(data.access)
      dispatch(refreshTokenAction(data.access))
      originalRequest.headers.Authorization = `Bearer ${data.access}`
      return originalRequest
    } catch (error) {
      dispatch(logoutUser())
      history.push(loginRoute)
    }
  } else {
    dispatch(logoutUser())
  }
}

export const handleError = (error) => {
  if (!error.response) return
  const { data, config } = error?.response
  if (error?.response.status === 403) {
    history.push(unauthorizedRoute)
  } else if (error?.response.status === 401) {
    history.push(unauthorizedRoute)
  } else if (error?.response?.status === 400 && !config.url.includes("survey")) {
    const name =
      Object.entries(data)[0][0] === "0" || Object.entries(data)[0][0] === "non_field_errors"
        ? ""
        : `${Object.entries(data)[0][0]}:`
    const message = `${name} ${Object.entries(data)[0][1]}`
    dispatch(openToast({ message, severity: "error" }))
  } else if (error?.response?.status === 404) {
    dispatch(openToast(generalErrorPayload.error))
  } else {
    dispatch(openToast(generalErrorPayload.error))
  }
}

export const resetPendingStatus = () => {
  dispatch(
    setPendingAction({
      fetch: false,
      create: false,
      delete: false,
      count: 0,
    })
  )
}
