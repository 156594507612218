import React from "react"

export const CameraIcon = ({ width, height }) => {
  return (
    <svg width={width} height={height} viewBox="0 0 59 50" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M56.0808 8.87085V5.96488H50.2688V8.87085H46.5521L43.6461 0.152954H16.208L13.3021 8.87085H0.867432V49.5543H58.9867V8.87085H56.0808ZM53.1748 43.7424H6.67936V14.6828H17.4925L20.3984 5.96488H39.4557L42.3617 14.6828H53.1748V43.7424Z"
        fill="#E1E1E1"
      />
      <path
        d="M29.9272 14.6827C27.3408 14.6827 24.8126 15.4497 22.6621 16.8866C20.5116 18.3235 18.8355 20.3658 17.8458 22.7553C16.856 25.1448 16.597 27.7741 17.1016 30.3107C17.6062 32.8474 18.8516 35.1775 20.6805 37.0063C22.5093 38.8351 24.8394 40.0806 27.376 40.5851C29.9127 41.0897 32.542 40.8308 34.9315 39.841C37.321 38.8512 39.3633 37.1752 40.8002 35.0247C42.2371 32.8742 43.004 30.3459 43.004 27.7596C43.0002 24.2926 41.6212 20.9687 39.1697 18.5171C36.7181 16.0656 33.3942 14.6866 29.9272 14.6827ZM29.9272 35.0245C28.4903 35.0245 27.0857 34.5984 25.891 33.8001C24.6963 33.0019 23.7651 31.8672 23.2153 30.5397C22.6654 29.2123 22.5216 27.7515 22.8019 26.3423C23.0822 24.933 23.7741 23.6385 24.7901 22.6225C25.8061 21.6065 27.1006 20.9146 28.5099 20.6343C29.9191 20.3539 31.3799 20.4978 32.7073 21.0477C34.0348 21.5975 35.1695 22.5287 35.9677 23.7234C36.766 24.9181 37.1921 26.3227 37.1921 27.7596C37.1921 29.6864 36.4267 31.5342 35.0643 32.8966C33.7018 34.2591 31.854 35.0245 29.9272 35.0245Z"
        fill="#E1E1E1"
      />
    </svg>
  )
}
