import React from "react"
import { connect } from "react-redux"
import { Button, CircularProgress } from "@material-ui/core"

const ButtonWithLoader = (props) => {
  const { title, isPosting, Delete, isDeleting, disabled } = props

  return (
    <>
      {!Delete && (
        <Button {...props} disabled={isPosting || disabled} style={{ borderRadius: "7px" }}>
          {!isPosting && title}
          {isPosting && <CircularProgress color="light" size={24} />}
        </Button>
      )}
      {Delete && (
        <Button {...props} disabled={isDeleting}>
          {!isDeleting && title}
          {isDeleting && <CircularProgress color="light" size={24} />}
        </Button>
      )}
    </>
  )
}

const mapStateToProps = ({ pending }) => ({
  isPosting: pending.create,
  isDeleting: pending.delete,
})

export default connect(mapStateToProps)(ButtonWithLoader)
