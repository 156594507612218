import { FETCH_ORDERS, SELECT_ORDER } from "../actions/types"

const INITIAL_STATE = {
  orders: null,
  selectedOrder: null,
}

export default (state = INITIAL_STATE, { type, payload }) => {
  switch (type) {
    case FETCH_ORDERS:
      return { ...state, orders: payload }
    case SELECT_ORDER:
      return { ...state, selectedOrder: payload }

    default:
      return state
  }
}
