import { FETCH_CUSTOMERS, RESET_SELECTED_CUSTOMERS, SET_SELECTED_CUSTOMERS } from "../actions/types"

const INITIAL_STATE = { users: {}, selectedUsers: [] }

export default (state = INITIAL_STATE, { type, payload }) => {
  switch (type) {
    case FETCH_CUSTOMERS:
      return { ...state, users: payload }
    case SET_SELECTED_CUSTOMERS:
      return { ...state, selectedUsers: payload }
    case RESET_SELECTED_CUSTOMERS:
      return { ...state, selectedUsers: payload }
    default:
      return state
  }
}
