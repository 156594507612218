import React from "react"
import { Link as RouterLink } from "react-router-dom"
import { makeStyles } from "@material-ui/styles"
import { AppBar, Button, Toolbar } from "@material-ui/core"
import LockIcon from "@material-ui/icons/Lock"
import { privacyRoute, welcomeRoute } from "src/constants"
import { SurvLogoIcon } from "src/components/Icons/SurvLogo"

const useStyles = makeStyles(() => ({
  root: {
    boxShadow: "none",
  },
  bar: {
    display: "flex",
    justifyContent: "space-between",
  },
  logo: {
    height: "56px",
    width: "117px",
    padding: "2px",
  },
}))

function Topbar() {
  const classes = useStyles()

  return (
    <AppBar className={classes.root} color="primary" position="static">
      <Toolbar className={classes.bar}>
        <RouterLink to={welcomeRoute}>
          <SurvLogoIcon />
        </RouterLink>
        <Button startIcon={<LockIcon />} component={RouterLink} to={privacyRoute} color="inherit">
          Privacy Policy
        </Button>
      </Toolbar>
    </AppBar>
  )
}

export default Topbar
