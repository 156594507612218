import React, { Suspense } from "react"
import { connect } from "react-redux"
import { renderRoutes } from "react-router-config"
import { Box, LinearProgress } from "@material-ui/core"
import { composeRoutes } from "src/utils/routeConfig"
import { makeStyles } from "@material-ui/styles"
import AuthGuard from "src/components/Singles/AuthGuard"
import CustomizedSnackbar from "src/components/Singles/CustomizedToast"
import Modal from "src/components/Singles/Dialog"
import TopBar from "./TopBar"
import Alert from "../Common/Alert"

const useStyles = makeStyles(() => ({
  container: {
    minHeight: "100vh",
    display: "flex",
    overflow: "hidden",
    "@media all and (-ms-high-contrast:none)": {
      height: 0,
    },
  },
  content: {
    paddingTop: 64,
    flexGrow: 1,
    maxWidth: "100%",
    overflowX: "hidden",
  },
}))

function WelcomeLayout({ route, session, staff, messagesCount }) {
  const classes = useStyles()

  return (
    <>
      <TopBar messagesCount={messagesCount} role={session.user.role} staff={staff} />
      <Box className={classes.container}>
        <Box className={classes.content}>
          <Suspense fallback={<LinearProgress />}>
            <AuthGuard route={route}>
              <Alert staff={staff} message="Your brand is pending approval" variant="warning" title="Warning" />
              {renderRoutes(composeRoutes(route.routes, session))}
              <CustomizedSnackbar />
              <Modal />
            </AuthGuard>
          </Suspense>
        </Box>
      </Box>
    </>
  )
}

const mapStateToProps = ({ session, branch, messages, venues }) => ({
  session,
  branch,
  messagesCount: messages?.count?.count,
  loggedIn: session.loggedIn,
  brandId: session.user.brandId,
  staff: venues.staffDetails,
})

export default connect(mapStateToProps)(WelcomeLayout)
