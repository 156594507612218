import {
  FETCH_QR_CARDS,
  DELETE_QR_CARD,
  SELECT_QR_CARDS,
  RESET_SELECTED_QR_CARDS,
  UPDATE_QR_CARD,
  CREATE_QR_CARD,
  RETRIEVE_QR_CARD,
} from "src/store/actions/types"

export const fetchQRCardsAction = (data) => (dispatch) => {
  dispatch({ type: FETCH_QR_CARDS, payload: data })
}

export const retrieveQRCardAction = (data) => (dispatch) => {
  dispatch({ type: RETRIEVE_QR_CARD, payload: data })
}

export const createQRCardAction = (data) => (dispatch) => {
  dispatch({ type: CREATE_QR_CARD, payload: data })
}

export const updateQRCardAction = (data) => (dispatch) => {
  dispatch({ type: UPDATE_QR_CARD, payload: data })
}

export const deleteQRCardAction = (cards) => (dispatch) => {
  dispatch({ type: DELETE_QR_CARD, payload: cards })
}

export const selectQRCardsAction = (cards) => (dispatch) => {
  dispatch({ type: SELECT_QR_CARDS, payload: cards })
}

export const resetSelectedQRCardsAction = () => (dispatch) => {
  dispatch({ type: RESET_SELECTED_QR_CARDS })
}
