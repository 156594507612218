import {
  FETCH_QR_CARDS,
  DELETE_QR_CARD,
  SELECT_QR_CARDS,
  CREATE_QR_CARD,
  RESET_SELECTED_QR_CARDS,
  RESET_QR_CARDS,
  UPDATE_QR_CARD,
  RETRIEVE_QR_CARD,
  UPDATE_CARDS_PENDING,
} from "../actions/types"

const INITIAL_STATE = {
  qrCards: null,
  qrCard: {},
  selectedCards: [],
  pending: {
    fetch: true,
    retrieve: true,
    create: false,
    update: false,
    delete: { uuid: "" },
  },
}

export default (state = INITIAL_STATE, { type, payload }) => {
  switch (type) {
    case FETCH_QR_CARDS:
      return { ...state, qrCards: payload }
    case RETRIEVE_QR_CARD:
      return { ...state, qrCard: payload }
    case CREATE_QR_CARD:
      return { ...state }
    case UPDATE_QR_CARD:
      return { ...state }
    case DELETE_QR_CARD:
      return {
        ...state,
        qrCards: payload.filteredCards,
        selectedCards: payload.filteredSelectCards,
      }
    case SELECT_QR_CARDS:
      return { ...state, selectedCards: payload }
    case RESET_QR_CARDS:
      return { ...state, qrCards: null, qrCard: {}, selectedCards: [] }
    case RESET_SELECTED_QR_CARDS:
      return { ...state, selectedCards: [] }
    case UPDATE_CARDS_PENDING:
      return { ...state, pending: { ...state.pending, ...payload } }
    default:
      return state
  }
}
