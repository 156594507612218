import {
  BranchIcon,
  CustomerIcon,
  DashboardIcon,
  // MenuIcon,
  // OrderIcon,
  SurveyIcon,
  QrTabIcon,
  HyperLinkIcon,
} from "src/components/Icons"

// import PresentIcon from 'src/components/Icons/Present'
import {
  branchRoute,
  customerRoute,
  // getMenuRoute,
  // getLoyaltyRoute,
  // menuTabs,
  // loyaltyTabs,
  // getOrderRoute,
  // orderTabs,
  getDashboardRoute,
  getQrCardsRoute,
  managementTabs,
  getManagementRoute,
  urlsRoute,
  getSurveyTab,
  surveyTabs,
} from "src/constants"

export const navConfig = [
  {
    items: [
      {
        title: "Dashboard",
        href: getDashboardRoute({}),
        icon: DashboardIcon,
        gate: "view_brand_all",
      },
      {
        title: "Customers",
        href: customerRoute,
        icon: CustomerIcon,
        gate: "view_brand_all",
      },
      {
        title: "QR Cards",
        href: () => getQrCardsRoute({}),
        icon: QrTabIcon,
        gate: "view_branch_all",
      },
      {
        title: "Shortened URLs",
        href: urlsRoute,
        icon: HyperLinkIcon,
        gate: "view_branch_all",
      },
      {
        title: "Management",
        href: () => getManagementRoute({ tab: managementTabs.brand }),
        icon: BranchIcon,
        gate: "view_brand_all",
      },
      // {
      //   title: 'Menus',
      //   href: () => getMenuRoute({ tab: menuTabs.menus }),
      //   icon: MenuIcon,
      //   gate: 'view_brand_all',
      // },
      {
        title: "Branches",
        href: branchRoute,
        icon: BranchIcon,
        gate: "view_branch_all",
      },
      // {
      //   title: 'Orders',
      //   href: getOrderRoute({ tab: orderTabs.active, page: 0 }),
      //   icon: OrderIcon,
      //   gate: 'view_branch_all',
      // },
      {
        title: "Surveys",
        href: () => getSurveyTab({ tab: surveyTabs.surveys }),
        icon: SurveyIcon,
        gate: "view_surveybuilder_all",
      },
      // {
      //   title: 'Loyalty',
      //   href: getLoyaltyRoute({ tab: loyaltyTabs.coupons }),
      //   icon: PresentIcon,
      //   gate: 'view_loyalty_all',
      // },
    ],
  },
]
