import {
  FETCH_TEMPLATES,
  DELETE_TEMPLATE,
  FETCH_TEMPLATE,
  UPDATE_TEMPLATE,
  ADD_TEMPLATE,
  SELECT_TEMPLATE,
} from "../actions/types"

const INITIAL_STATE = {
  templates: [],
  template: null,
  selectedTemplate: { name: "", design: "", x: 0, y: 0, w: 0, h: 0 },
}

export default (state = INITIAL_STATE, { type, payload }) => {
  switch (type) {
    case FETCH_TEMPLATES:
      return { ...state, templates: payload, selectedTemplate: payload[0] || {} }
    case FETCH_TEMPLATE:
      return { ...state, template: payload }
    case DELETE_TEMPLATE:
      return { ...state, templates: payload }
    case UPDATE_TEMPLATE:
      return { ...state, templates: payload }
    case ADD_TEMPLATE:
      return { ...state, templates: payload }
    case SELECT_TEMPLATE:
      return { ...state, selectedTemplate: payload }

    default:
      return state
  }
}
