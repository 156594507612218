import { applyMiddleware, createStore, compose } from "redux"
import { useDispatch as useReduxDispatch, useSelector as useReduxSelector } from "react-redux"
import thunkMiddleware from "redux-thunk"
import { composeWithDevTools } from "redux-devtools-extension"
import rootReducer from "src/store/reducers"

// export default function configureStore(preloadedState = {}) {
const preloadedState = {}
const middleware = [thunkMiddleware]
const middlewareEnhancer = composeWithDevTools(applyMiddleware(...middleware))

const enhancers = [middlewareEnhancer]
const composedEnhancers = compose(...enhancers)

const store = createStore(rootReducer, preloadedState, composedEnhancers)

export const useSelector = useReduxSelector

export const useDispatch = () => useReduxDispatch()

export default store
