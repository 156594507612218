import React from "react"
import { Box, Button, Grid, Paper, Typography } from "@material-ui/core"
import { makeStyles } from "@material-ui/styles"
import { ResizeIcon } from "src/components/Icons/Resize"
import { TrashIcon } from "src/components/Icons/Trash"
import { Link } from "react-router-dom"
import { getCardRoute, getReviewRoute, getStaffRoute, getSubscriptionCardRoute } from "src/constants"
import useQueryParams from "src/Hooks/useQueryParams"

const useStyles = makeStyles(() => ({
  header: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  container: {
    marginTop: "42px",
    padding: "40px",
    borderRadius: "14px",
  },
  childContainer: {
    backgroundColor: "#f4f6f8",
    borderRadius: "8px",
    marginTop: "40px",
  },
  branchContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  actionsContainer: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
  },
  actionBtn: {
    background: "#C2C6D2",
    borderRadius: "7px",
    color: "white",
    height: "32px",
  },
  navigator: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    height: "50px",
    marginTop: "40px",
    borderBottom: "1px solid #C2C6D2",
  },
  navigatorItem: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    height: "100%",
    borderRadius: "3px",
    cursor: "pointer",
  },
  navigatorText: {
    fontFamily: "Roboto",
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "18px",
    lineHeight: "14px",
    letterSpacing: "0.02em",
    color: "#ADB5C4",
  },
}))

const routes = [
  { label: "QR Cards", to: (id) => getCardRoute(id) },
  { label: "Staff", to: (id) => getStaffRoute(id, "approved") },
  { label: "Reviews", to: (id) => getReviewRoute(id, { status: "current" }) },
  { label: "Subscription QR", to: (id) => getSubscriptionCardRoute(id) },
]

const Details = ({ children, branch, handleDeleteBranch, handleCloseBranch }) => {
  const classes = useStyles()
  const { branchTab } = useQueryParams()

  return (
    <Paper className={classes.container}>
      <Grid container justifyContent="center">
        <Grid item xs={12}>
          <Box className={classes.header}>
            <Box>
              <Typography variant="h4" gutterBottom>
                {branch.name}
              </Typography>
              <Typography variant="subtitle2">{branch.address}</Typography>
            </Box>
            <Box className={classes.actionsContainer}>
              <Button variant="contained" className={classes.actionBtn} onClick={handleCloseBranch}>
                <ResizeIcon />
              </Button>
              <Box width="10px" />
              <Button variant="contained" className={classes.actionBtn} onClick={handleDeleteBranch}>
                <TrashIcon height="23px" width="23px" color="white" />
              </Button>
            </Box>
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Box className={classes.navigator}>
            {routes.map((item, i) => (
              <Button
                className={classes.navigatorItem}
                style={{
                  borderBottom: parseInt(branchTab, 16) === i ? "3px solid #000000" : "",
                }}
                component={Link}
                to={item.to(branch.id)}
              >
                <Typography
                  className={classes.navigatorText}
                  style={{ color: parseInt(branchTab, 16) === i ? "#263238" : "" }}
                >
                  {item.label}
                </Typography>
              </Button>
            ))}
          </Box>
        </Grid>
        <Grid item xs={12}>
          {children}
        </Grid>
      </Grid>
    </Paper>
  )
}

export default Details
