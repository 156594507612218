import { useEffect, useState } from "react"
import { useLocation } from "react-router"

export default function useQueryParams() {
  const { search } = useLocation()
  const [params, setParams] = useState({})
  useEffect(() => {
    const queryParams = new URLSearchParams(search)
    const paramsObject = {}
    queryParams.forEach((value, key) => {
      paramsObject[key] = value
    })
    setParams(paramsObject)
  }, [search])

  return params
}
