import React from "react"

export const SurveyIcon = () => {
  return (
    <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        opacity="0.5"
        d="M12.128 0.85372L6.26099 3.29829V10.346L12.128 12.7906C12.3389 12.8766 12.5677 12.9096 12.7943 12.8866C13.0209 12.8636 13.2384 12.7854 13.4277 12.6589C13.6171 12.5323 13.7726 12.3613 13.8805 12.1607C13.9884 11.9601 14.0455 11.7361 14.0467 11.5084V2.13597C14.0455 1.90821 13.9884 1.68423 13.8805 1.48365C13.7726 1.28307 13.6171 1.11201 13.4277 0.985459C13.2384 0.858908 13.0209 0.780724 12.7943 0.757756C12.5677 0.734789 12.3389 0.767739 12.128 0.85372V0.85372Z"
        fill="#79869F"
      />
      <path
        opacity="0.5"
        d="M0.117328 6.05651V7.59705C0.117328 8.2087 0.360302 8.79529 0.792799 9.22778C1.2253 9.66028 1.81189 9.90326 2.42353 9.90326H2.96779V12.9105C2.96779 13.2164 3.08928 13.5097 3.30553 13.7259C3.52178 13.9422 3.81507 14.0636 4.12089 14.0636C4.27232 14.0636 4.42227 14.0338 4.56217 13.9759C4.70207 13.9179 4.82918 13.833 4.93626 13.7259C5.04333 13.6188 5.12827 13.4917 5.18622 13.3518C5.24417 13.2119 5.27399 13.062 5.27399 12.9105V3.75031H2.42353C2.12033 3.74909 1.8199 3.80791 1.53954 3.92337C1.25919 4.03884 1.00447 4.20867 0.790078 4.42306C0.575684 4.63745 0.405858 4.89217 0.290392 5.17253C0.174927 5.45288 0.116108 5.75332 0.117328 6.05651V6.05651Z"
        fill="#79869F"
      />
    </svg>
  )
}
