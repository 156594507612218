import {
  DELETE_SURVEY,
  FETCH_SURVEYS_LIST,
  FETCH_SURVEY_ANALYTICS,
  FETCH_SURVEY_FOLDER,
  UPDATE_SURVEY_FOLDER,
  DELETE_SURVEY_FOLDER,
  OPEN_FOLDER,
  UPDATE_OPENED_FOLDER,
  FETCH_FOLDERS_SATISFACTION_ANALYTICS,
  FETCH_FOLDERS_NPS_ANALYTICS,
  FETCH_FOLDERS_REVIEWS_COUNT,
  FETCH_FOLDERS_NPS_TREND,
  FETCH_FOLDERS_RESPONSE_TREND,
  FETCH_FOLDERS_PERFORMANCE_TREND,
  FETCH_FOLDERS_SHORTLIST,
} from "../actions/types"

const INITIAL_STATE = {
  surveys: [],
  analytics: null,
  openedFolder: null,
  foldersDashboard: {
    performanceTrend: [],
    responseTrend: [],
    npsTrend: [],
    satisfactionAnalytics: [],
    npsAnalytics: [],
    reviewsCount: [],
    folders: [],
  },
}

export default (state = INITIAL_STATE, { type, payload }) => {
  switch (type) {
    case FETCH_SURVEYS_LIST:
      return { ...state, surveys: payload }
    case FETCH_SURVEY_ANALYTICS:
      return { ...state, analytics: payload }
    case DELETE_SURVEY:
      return { ...state, surveys: payload }
    case FETCH_SURVEY_FOLDER:
      return { ...state, folder: payload }
    case UPDATE_SURVEY_FOLDER:
      return { ...state, folder: payload }
    case DELETE_SURVEY_FOLDER:
      return { ...state, folders: payload }
    case OPEN_FOLDER:
      return { ...state, openedFolder: payload }
    case UPDATE_OPENED_FOLDER:
      return { ...state, openedFolder: payload }
    case FETCH_FOLDERS_SATISFACTION_ANALYTICS:
      return {
        ...state,
        foldersDashboard: {
          ...state.foldersDashboard,
          satisfactionAnalytics: payload,
        },
      }
    case FETCH_FOLDERS_NPS_ANALYTICS:
      return {
        ...state,
        foldersDashboard: {
          ...state.foldersDashboard,
          npsAnalytics: payload,
        },
      }
    case FETCH_FOLDERS_REVIEWS_COUNT:
      return {
        ...state,
        foldersDashboard: {
          ...state.foldersDashboard,
          reviewsCount: payload,
        },
      }
    case FETCH_FOLDERS_PERFORMANCE_TREND:
      return {
        ...state,
        foldersDashboard: {
          ...state.foldersDashboard,
          performanceTrend: payload,
        },
      }
    case FETCH_FOLDERS_RESPONSE_TREND:
      return {
        ...state,
        foldersDashboard: {
          ...state.foldersDashboard,
          responseTrend: payload,
        },
      }
    case FETCH_FOLDERS_NPS_TREND:
      return {
        ...state,
        foldersDashboard: {
          ...state.foldersDashboard,
          npsTrend: payload,
        },
      }
    case FETCH_FOLDERS_SHORTLIST:
      return {
        ...state,
        foldersDashboard: {
          ...state.foldersDashboard,
          folders: payload,
        },
      }
    default:
      return state
  }
}
