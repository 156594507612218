import React from "react"
import LinkIcon from "@material-ui/icons/Link"

export const HyperLinkIcon = () => {
  return (
    <LinkIcon
      style={{
        color: "#BCC3CF",
      }}
    />
  )
}
