import React from "react"
import { useField } from "formik"
import { TextField, Typography } from "@material-ui/core"
import { makeStyles } from "@material-ui/styles"

const useStyles = makeStyles({
  root: {
    borderRadius: "7px",
    color: "#79869F",
    fontWeight: "500",
    fontSize: "16px",
    border: "1px solid #DFDDDD",
    "&:hover": {
      border: "1px solid black",
      "& .MuiOutlinedInput-notchedOutline": {
        border: "none",
      },
    },
    "&.Mui-focused": {
      border: "1px solid #2E9DF4",
      "& .MuiOutlinedInput-notchedOutline": {
        border: "none",
      },
    },
    "& .MuiOutlinedInput-notchedOutline": {
      border: "none",
    },
    "& .MuiOutlinedInput-input": {
      border: "none",
      padding: "4px 8px",
      minHeight: "28px",
      display: "flex",
      alignItems: "center",
    },
    "& .MuiInputAdornment-root": {
      backgroundColor: "#F7F7FC",
      padding: "28px 35px",
      borderTopRightRadius: "7px",
      borderBottomRightRadius: "7px",
      marginRight: -14,
      borderLeft: "1px solid #9A9AB0;",
    },
    "& .MuiOutlinedInput-root": {
      paddingRight: 0,
    },
  },
  title: {
    fontWeight: "bold",
    fontSize: "16px",
    marginBottom: "8px",
  },
})

export const SingleField = ({ ...props }) => {
  const classes = useStyles()
  const [field, { touched, error }] = useField(props)
  const { titleClass, title, InputProps, dir } = props

  return (
    <>
      {title && (
        <Typography dir={dir} className={titleClass || classes.title}>
          {title}
        </Typography>
      )}
      <TextField
        {...field}
        {...props}
        InputProps={{ ...InputProps, className: classes.root }}
        error={touched && error ? error : null}
        helperText={touched && error}
      />
    </>
  )
}
