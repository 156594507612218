import React, { Suspense, useCallback, useEffect, useState } from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { renderRoutes } from "react-router-config"
import { makeStyles } from "@material-ui/styles"
import { Container, LinearProgress } from "@material-ui/core"
import AuthGuard from "src/components/Singles/AuthGuard"
import { composeRoutes } from "src/utils/routeConfig"
import { branchRoute, deleteBranch } from "src/constants"
import CustomizedSnackbar from "src/components/Singles/CustomizedToast"
import {
  addBranchAction,
  fetchBranchesAction,
  fetchBrandAction,
  openModal,
  selectBranchAction,
  setStaffDetailsAction,
} from "src/store/actions"
import { useHistory } from "react-router"
import { fetchBranches, fetchBrand, fetchStaff, fetchBranch } from "src/API"
import { addBranch, updateBranch } from "src/API/branch"
import { navConfig } from "src/layouts/navConfig"
import TopBar from "src/layouts/Dashboard/TopBar"
import NavBar from "src/layouts/Dashboard/NavBar"
import Modal from "src/components/Singles/Dialog"
import useQueryParams from "src/Hooks/useQueryParams"
import Header from "../common/Header"
import Details from "./Details"
import BranchModal from "../common/Modal"

const useStyles = makeStyles((theme) => ({
  container: {
    minHeight: "100vh",
    display: "flex",
    "@media all and (-ms-high-contrast:none)": {
      height: 0,
    },
  },
  content: {
    paddingTop: "80px",
    flexGrow: 1,
    maxWidth: "100%",
    overflowX: "hidden",
    [theme.breakpoints.up("lg")]: {
      paddingLeft: 256,
    },
    [theme.breakpoints.down("xs")]: {
      paddingTop: 56,
    },
    paddingBottom: theme.spacing(5),
    width: "100%",
    height: "100%",
  },
  root: {},
  header: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  addBtn: {
    borderRadius: "10px",
  },
  searchContainer: {
    height: "64px",
    marginTop: "42px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "10px",
  },
  table: {
    marginTop: "42px",
  },
  actionsContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  actionBtn: {
    background: "#C2C6D2",
    borderRadius: "7px",
    color: "white",
    height: "32px",
  },
}))

function Dashboard({
  route,
  session,
  brand,
  branch,
  staff,
  branches,
  brandId,
  selectedBranch,
  messagesCount,
  selectBranchAction,
  fetchBrandAction,
  fetchBranchesAction,
  addBranchAction,
  setStaffDetailsAction,
  openModal,
}) {
  const classes = useStyles()
  const history = useHistory()
  const { branchId } = useQueryParams()
  const [openNavBarMobile, setOpenNavBarMobile] = useState(false)
  const [open, setOpen] = useState(false)

  const fetchData = useCallback(async () => {
    if (brandId && branchId) {
      const [{ data }, { data: branchesData }] = await Promise.all([fetchBrand(brandId), fetchBranches()])
      fetchBrandAction(data)
      fetchBranchesAction(branchesData)
    }
    if (!staff) {
      const { data: staff } = await fetchStaff()
      setStaffDetailsAction(staff)
    }
  }, [branchId, brandId, staff, fetchBranchesAction, fetchBrandAction, setStaffDetailsAction])
  const fetchBranchData = useCallback(async () => {
    if (branchId) {
      const { data: branch } = await fetchBranch(branchId)
      selectBranchAction(branch)
    }
  }, [branchId, selectBranchAction])

  useEffect(() => {
    fetchBranchData()
    // eslint-disable-next-line
  }, [branchId])

  useEffect(() => {
    fetchData()
  }, [fetchData, branchId])

  const handleCloseModal = () => {
    handleSelectBranch({ name: "", address: "" })
    setOpen(false)
  }

  const handleDeleteBranch = () => {
    const data = branches.filter((b) => b.id !== branch.id)
    openModal({ ...deleteBranch, data: { data, location: branch } })
  }

  const handleSelectBranch = (branch) => {
    selectBranchAction(branch)
  }

  const handleCloseBranch = () => {
    handleSelectBranch({ name: "", address: "", success_factors: [] })
    history.push(branchRoute)
  }

  const handleSubmit = async (values) => {
    if (!values.id) {
      const { data } = await addBranch(brandId, values)
      addBranchAction([...branches, data])
    } else {
      await updateBranch(values)
    }
    handleCloseModal()
  }

  if (!branch?.id) {
    return <LinearProgress />
  }

  return (
    <>
      <TopBar
        onOpenNavBarMobile={() => setOpenNavBarMobile(true)}
        messagesCount={messagesCount}
        role={session.user.role}
        staff={staff}
      />
      <NavBar
        navConfig={navConfig}
        onMobileClose={() => setOpenNavBarMobile(false)}
        openMobile={openNavBarMobile}
        brand={brand}
      />
      <div className={classes.container}>
        <div className={classes.content}>
          <Container maxWidth={false}>
            <Header classes={classes} branchName={branch?.name} />
            <Details branch={branch} handleDeleteBranch={handleDeleteBranch} handleCloseBranch={handleCloseBranch}>
              <Suspense fallback={<LinearProgress />}>
                <AuthGuard route={route}>
                  {renderRoutes(composeRoutes(route.routes, session))}
                  <CustomizedSnackbar />
                  <Modal />
                </AuthGuard>
              </Suspense>
              <BranchModal
                open={open}
                selectedBranch={selectedBranch}
                handleSubmit={handleSubmit}
                handleCloseModal={handleCloseModal}
              />
            </Details>
          </Container>
        </div>
      </div>
    </>
  )
}

Dashboard.propTypes = {
  route: PropTypes.object,
}
const mapStateToProps = ({ session, branch, messages, venues }) => ({
  session,
  branch: branch.selectedBranch,
  branches: branch.branches,
  selectedBranch: branch.selectedBranch,
  messagesCount: messages?.count?.count,
  loggedIn: session.loggedIn,
  brandId: session.user.brandId,
  brand: venues?.venueBrand,
  staff: venues?.staffDetails,
})

export default connect(mapStateToProps, {
  selectBranchAction,
  addBranchAction,
  fetchBrandAction,
  openModal,
  fetchBranchesAction,
  setStaffDetailsAction,
})(Dashboard)
