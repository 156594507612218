import React from "react"

export const QRIcon = () => {
  return (
    <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="30" height="30" rx="4" fill="#0052D4" fillOpacity="0.17" />
      <path
        d="M8.18182 8.18176V12.4465H12.4465V8.18176H8.18182ZM11.2283 11.2282H9.40045V9.4004H11.2283V11.2282Z"
        fill="white"
      />
      <path
        d="M8.18182 18.5393V22.8043H12.4465V18.5393H8.18182ZM11.2283 21.586H9.40045V19.7582H11.2283V21.586Z"
        fill="white"
      />
      <path
        d="M18.5396 8.18176V12.4465H22.8043V8.18176H18.5396ZM21.5858 11.2282H19.758V9.4004H21.5858V11.2282Z"
        fill="white"
      />
      <path d="M21.5856 13.6651V17.3208H18.5394V18.5391H22.8041V13.665L21.5856 13.6651Z" fill="white" />
      <path d="M18.5396 19.7581V22.8043H19.758V20.9764H21.5858V22.8043H22.8045V19.7581H18.5396Z" fill="white" />
      <path d="M13.6655 8.18176V9.4004H16.1025V12.4466H17.3212V8.18189L13.6655 8.18176Z" fill="white" />
      <path
        d="M16.1023 13.6653V16.1026H13.6653V19.7582H16.1023V22.8044H17.321V18.5394H14.8839V17.321H17.321V14.884H18.5396V16.1026H19.758V13.6653L16.1023 13.6653Z"
        fill="white"
      />
      <path d="M13.6655 20.9764H14.8841V22.8043H13.6655V20.9764Z" fill="white" />
      <path d="M10.6189 16.1023H12.4467V17.3207H10.6189V16.1023Z" fill="white" />
      <path
        d="M13.6653 10.6184V13.6649H8.18182V17.3205H9.40045V14.8835H14.8839V10.6185L13.6653 10.6184Z"
        fill="white"
      />
    </svg>
  )
}
