import { useDispatch } from "react-redux"
import axios from "axios"
import jwt_decode from "jwt-decode"
import { BASE_URL } from "src/Config"
import { getAccessToken, getRefreshToken, setLocalAccessToken } from "src/utils/auth"
import {
  handleResponse,
  handleError,
  handleRequestPendingStatus,
  handleResponsePendingStatus,
  resetPendingStatus,
  handleUnAuthorizedError,
} from "src/utils/axios"
import { loginAction, logoutUser } from "src/store/actions"

const axiosInstance = axios.create()

axiosInstance.interceptors.request.use(
  function (config) {
    handleRequestPendingStatus(config)
    if (getAccessToken()) {
      const token = jwt_decode(getAccessToken())
      const isExpired = Date.now() >= token.exp * 1000
      if (!isExpired || !token || config?.url?.includes("/jwt/refresh/")) return config
      return handleUnAuthorizedError(config)
    }
    return config
  },
  function (error) {
    resetPendingStatus()
    return Promise.reject(error)
  }
)

axiosInstance.interceptors.response.use(
  function (response) {
    handleResponsePendingStatus()
    handleResponse(response)
    return response
  },
  function (error) {
    const originalRequest = error.config
    const refreshEndpoint = "/api/auth/jwt/refresh/"

    if (
      (error.response?.status === 400 || error.response?.status === 403) &&
      originalRequest.url === `${BASE_URL}${refreshEndpoint}`
    ) {
      // Don't call the logout endpoint here since that would cause an infinite loop due to the endpoint returning 401
      logoutUser()
      return Promise.reject(error)
    }

    if (error.response?.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true
      const refreshToken = getRefreshToken()
      return axiosInstance
        .post(`${BASE_URL}${refreshEndpoint}`, {
          refresh: refreshToken,
        })
        .then((res) => {
          const dispatch = useDispatch()

          if (res.status === 200) {
            setLocalAccessToken(res.data.access)
            dispatch(loginAction(res.data))
            axiosInstance.defaults.headers.common.Authorization = `Bearer ${getAccessToken()}`
            return axiosInstance(originalRequest)
          }
        })
    }

    resetPendingStatus()
    handleError(error)
    return Promise.reject(error)
  }
)

export default axiosInstance
