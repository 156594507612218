import { FETCH_CUSTOMERS, RESET_SELECTED_CUSTOMERS, SET_SELECTED_CUSTOMERS } from "./types"

export const fetchCustomersAction = (data) => (dispatch) => {
  dispatch({ type: FETCH_CUSTOMERS, payload: data })
}

export const setSelectedCustomers = (users) => (dispatch) => {
  dispatch({ type: SET_SELECTED_CUSTOMERS, payload: users })
}

export const resetSelectedCustomers = () => (dispatch) => {
  dispatch({ type: RESET_SELECTED_CUSTOMERS, payload: [] })
}
