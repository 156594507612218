import React from "react"

export const EmptyMenuIcon = ({ width = "47", height = "41", color = "#79869F" }) => {
  return (
    <svg width={width} height={height} viewBox="0 0 47 41" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M46.1 0.525483C45.6 0.125483 44.8 -0.0745166 44.2 0.0254834L30.5 2.72548L16.9 0.0254834H16.8C16.7 0.0254834 16.6 0.0254834 16.4 0.0254834C16.3 0.0254834 16.2 0.0254834 16.1 0.0254834H16L1.9 2.82548C0.8 3.02548 0 4.02548 0 5.12548V38.0255C0 38.7255 0.3 39.4255 0.9 39.8255C1.3 40.1255 1.9 40.3255 2.4 40.3255C2.6 40.3255 2.7 40.3255 2.8 40.3255L16.4 37.6255L30 40.3255H30.1C30.2 40.3255 30.4 40.3255 30.5 40.3255C30.6 40.3255 30.8 40.3255 30.9 40.3255L45 37.5255C46.1 37.3255 46.9 36.3255 46.9 35.2255V2.42548C47 1.72548 46.7 1.02548 46.1 0.525483ZM4.7 7.02548L14.1 5.22548V33.3255L4.7 35.1255V7.02548ZM18.8 5.22548L28.2 7.02548V35.1255L18.8 33.3255V5.22548ZM42.3 33.3255L32.9 35.1255V7.02548L42.3 5.22548V33.3255Z"
        fill={color}
      />
    </svg>
  )
}
