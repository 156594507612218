import React from "react"
import { Box } from "@material-ui/core"
import { Alert as MUIAlert, AlertTitle } from "@material-ui/lab"

const Alert = ({ staff, message, title, variant }) => {
  return (
    <>
      {!staff?.is_brand_approved && staff?.brand && (
        <Box display="flex" justifyContent="center">
          <Box mt={2} width="30%">
            <MUIAlert severity={variant}>
              <AlertTitle>{title}</AlertTitle>
              {message}
            </MUIAlert>
          </Box>
        </Box>
      )}
    </>
  )
}

export default Alert
