import { GET_ALL_MESSAGES, GET_UNREAD_MESSAGE_BY_ID, READ_MESSAGE, GET_UNREAD_COUNT } from "../actions/types"

const INITIAL_STATE = {
  messages: {
    unread: [],
    read: [],
  },
}

export default (state = INITIAL_STATE, { type, payload }) => {
  switch (type) {
    case GET_ALL_MESSAGES:
      return { ...state, messages: payload }
    case GET_UNREAD_MESSAGE_BY_ID:
      return { ...state, unread: payload }
    case READ_MESSAGE:
      return { ...state, read: payload }
    case GET_UNREAD_COUNT:
      return { ...state, count: payload }
    default:
      return state
  }
}
