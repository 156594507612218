import { ERROR } from "../actions/types"

const INITIAL_STATE = {
  error: null,
}

export default (state = INITIAL_STATE, { type, payload }) => {
  switch (type) {
    case ERROR:
      return { error: payload }
    default:
      return state
  }
}
