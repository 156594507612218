import { BASE_URL } from "src/Config"
import axiosInstance from "./axios"

export const login = (user) => {
  return axiosInstance.post(`${BASE_URL}/api/auth/login/`, user)
}

export const refreshToken = (refresh) => {
  return axiosInstance.post(`${BASE_URL}/api/auth/jwt/refresh/`, refresh)
}

export const loginWithFacebook = (token) => {
  return axiosInstance.post(`${BASE_URL}/api/auth/facebook/`, token)
}

export const loginWithGoogle = (token) => {
  return axiosInstance.post(`${BASE_URL}/api/auth/google/`, token)
}

export const register = (user) => {
  return axiosInstance.post(`${BASE_URL}/api/auth/registration/`, user)
}
