export const FETCH_BRANCHES = "FETCH_BRANCHES"
export const FETCH_BRANCH = "FETCH_BRANCH"
export const SELECT_BRANCH = "SELECT_BRANCH"
export const DELETE_BRANCH = "DELETE_BRANCH"
export const ADD_BRANCH = "DELETE_BRANCH"
export const UPDATE_BRANCH = "DELETE_BRANCH"

export const GET_BRAND_DASHBOARD = "GET_BRAND_DASHBOARD"

export const FETCH_BRAND_REGIONS_PERFORMANCE = "FETCH_BRAND_REGIONS_PERFORMANCE"
export const FETCH_BRAND_AREAS_PERFORMANCE = "FETCH_BRAND_AREAS_PERFORMANCE"
export const FETCH_BRAND_BRANCHES_PERFORMANCE = "FETCH_BRAND_BRANCHES_PERFORMANCE"
export const FETCH_BRAND_STAFF_PERFORMANCE = "FETCH_BRAND_STAFF_PERFORMANCE"

export const FETCH_BRAND_PERFORMANCE_TREND = "FETCH_BRAND_PERFORMANCE_TREND"
export const FETCH_BRAND_RESPONSE_TREND = "FETCH_BRAND_RESPONSE_TREND"
export const FETCH_BRAND_NPS_TREND = "FETCH_BRAND_NPS_TREND"

export const GET_BRANCH_DASHBOARD = "GET_BRANCH_DASHBOARD"

export const FETCH_BRANCH_STAFF_PERFORMANCE = "FETCH_BRANCH_STAFF_PERFORMANCE"

export const FETCH_BRANCH_PERFORMANCE_TREND = "FETCH_BRANCH_PERFORMANCE_TREND"
export const FETCH_BRANCH_RESPONSE_TREND = "FETCH_BRANCH_RESPONSE_TREND"
export const FETCH_BRANCH_NPS_TREND = "FETCH_BRANCH_NPS_TREND"

export const GET_REGION_DASHBOARD = "GET_REGION_DASHBOARD"

export const FETCH_REGION_AREAS_PERFORMANCE = "FETCH_REGION_AREAS_PERFORMANCE"
export const FETCH_REGION_BRANCHES_PERFORMANCE = "FETCH_REGION_BRANCHES_PERFORMANCE"
export const FETCH_REGION_STAFF_PERFORMANCE = "FETCH_REGION_STAFF_PERFORMANCE"

export const FETCH_REGION_PERFORMANCE_TREND = "FETCH_REGION_PERFORMANCE_TREND"
export const FETCH_REGION_RESPONSE_TREND = "FETCH_REGION_RESPONSE_TREND"
export const FETCH_REGION_NPS_TREND = "FETCH_REGION_NPS_TREND"

export const GET_AREA_DASHBOARD = "GET_AREA_DASHBOARD"

export const FETCH_AREA_BRANCHES_PERFORMANCE = "FETCH_AREA_BRANCHES_PERFORMANCE"
export const FETCH_AREA_STAFF_PERFORMANCE = "FETCH_AREA_STAFF_PERFORMANCE"

export const FETCH_AREA_PERFORMANCE_TREND = "FETCH_AREA_PERFORMANCE_TREND"
export const FETCH_AREA_RESPONSE_TREND = "FETCH_AREA_RESPONSE_TREND"
export const FETCH_AREA_NPS_TREND = "FETCH_AREA_NPS_TREND"

export const FETCH_STAFF = "FETCH_STAFF"

export const FETCH_STAFF_DETAILS = "FETCH_STAFF_DETAILS"
export const FETCH_APPROVED_STAFF = "FETCH_APPROVED_STAFF"
export const FETCH_PENDING_STAFF = "FETCH_PENDING_STAFF"
export const DELETE_APPROVED_STAFF = "DELETE_APPROVED_STAFF"
export const DELETE_PENDING_STAFF = "DELETE_PENDING_STAFF"
export const RESET_APPROVED_STAFF = "RESET_APPROVED_STAFF"
export const FETCH_COMPLAINTS_ANALYTICS = "FETCH_COMPLAINTS_ANALYTICS"

export const APPROVE_STAFF = "APPROVE_STAFF"
export const UPDATE_STAFF = "UPDATE_STAFF"
export const GET_MEMBER_DASHBOARD = "GET_MEMBER_DASHBOARD"
export const POST_DAILY_KEY = "POST_DAILY_KEY"

export const FETCH_STAFF_PERFORMANCE_TREND = "FETCH_STAFF_PERFORMANCE_TREND"
export const FETCH_STAFF_RESPONSE_TREND = "FETCH_STAFF_RESPONSE_TREND"
export const FETCH_STAFF_NPS_TREND = "FETCH_STAFF_NPS_TREND"

export const SET_FROM_DATE = "SET_FROM_DATE"
export const SET_TO_DATE = "SET_TO_DATE"

export const FETCH_VENUE_INDUSTRIES = "FETCH_VENUE_INDUSTRIES"
export const FETCH_VENUE_LOCATIONS = "FETCH_VENUE_LOCATIONS"

export const FETCH_QR_CARDS = "FETCH_QR_CARDS"
export const RETRIEVE_QR_CARD = "RETRIEVE_QR_CARD"
export const CREATE_QR_CARD = "CREATE_QR_CARD"
export const UPDATE_QR_CARD = "UPDATE_QR_CARD"
export const DELETE_QR_CARD = "DELETE_QR_CARD"
export const SELECT_QR_CARDS = "SELECT_QR_CARDS"
export const RESET_QR_CARDS = "RESET_QR_CARDS"
export const RESET_SELECTED_QR_CARDS = "RESET_SELECTED_QR_CARDS"
export const UPDATE_CARDS_PENDING = "UPDATE_CARDS_PENDING"

export const CREATE_MENU = "CREATE_MENU"
export const EDIT_MENU = "EDIT_MENU"
export const FETCH_MENUS = "FETCH_MENUS"
export const DELETE_MENU = "DELETE_MENU"
export const FETCH_DYNAMIC_MENUS = "FETCH_DYNAMIC_MENUS"
export const RETRIEVE_DYNAMIC_MENUS = "RETRIEVE_DYNAMIC_MENUS"
export const CREATE_DYNAMIC_MENU = "CREATE_DYNAMIC_MENU"
export const UPDATE_DYNAMIC_MENU = "UPDATE_DYNAMIC_MENU"
export const DELETE_DYNAMIC_MENU = "DELETE_DYNAMIC_MENU"
export const SELECT_DYNAMIC_MENU = "SELECT_DYNAMIC_MENU"
export const FETCH_MENU_GROUPS = "FETCH_MENU_GROUPS"
export const CREATE_MENU_GROUP = "CREATE_MENU_GROUP"
export const UPDATE_MENU_GROUP = "UPDATE_MENU_GROUP"
export const UPDATE_MENU_GROUPS = "UPDATE_MENU_GROUPS"
export const DELETE_MENU_GROUP = "DELETE_MENU_GROUP"
export const FETCH_MENU_ITEMS = "FETCH_MENU_ITEMS"
export const SELECT_MENU_ITEM = "SELECT_MENU_ITEM"
export const CREATE_MENU_ITEM = "CREATE_MENU_ITEM"
export const UPDATE_MENU_ITEM = "UPDATE_MENU_ITEM"
export const DELETE_MENU_ITEM = "DELETE_MENU_ITEM"
export const SET_MODAL_STATUS = "SET_MODAL_STATUS"
export const FETCH_PERSONAL_DATA = "FETCH_PERSONAL_DATA"

export const SESSION_LOGIN = "SESSION_LOGIN"
export const SESSION_LOGOUT = "SESSION_LOGOUT"
export const SESSION_UPDATED = "SESSION_UPDATED"
export const REFRESH_TOKEN = "REFRESH_TOKEN"

export const SET_CURRENT_USER = "SET_CURRENT_USER"

export const FETCH_CUSTOMERS = "FETCH_CUSTOMERS"
export const SET_SELECTED_CUSTOMERS = "SET_SELECTED_CUSTOMERS"
export const RESET_SELECTED_CUSTOMERS = "RESET_SELECTED_CUSTOMERS"

export const ERROR = "ERROR"

export const FETCH_VENUE_BRAND = "FETCH_VENUE_BRAND"
export const UPDATE_VENUE_BRAND = "UPDATE_VENUE_BRAND"

export const GET_ALL_MESSAGES = "GET_ALL_MESSAGES"
export const GET_UNREAD_MESSAGE_BY_ID = "GET_UNREAD_MESSAGE_BY_ID"
export const READ_MESSAGE = "READ_MESSAGE"
export const GET_UNREAD_COUNT = "GET_UNREAD_COUNT"

export const FETCH_SURVEYS_LIST = "FETCH_SURVEYS_LIST"
export const FETCH_SURVEY_ANALYTICS = "FETCH_SURVEY_ANALYTICS"
export const DELETE_SURVEY = "DELETE_SURVEY"

export const FETCH_SURVEY_FOLDER = "FETCH_SURVEY_FOLDER"
export const DELETE_SURVEY_FOLDER = "DELETE_SURVEY_FOLDER"
export const FETCH_FOLDERS_SHORTLIST = "FETCH_FOLDERS_SHORTLIST"

export const UPDATE_SURVEY_FOLDER = "DELETE_SHORTENED_URL"
export const OPEN_FOLDER = "OPEN_FOLDER"
export const UPDATE_OPENED_FOLDER = "UPDATE_OPENED_FOLDER"

export const FETCH_FOLDERS_PERFORMANCE_TREND = "FETCH_FOLDERS_PERFORMANCE_TREND"
export const FETCH_FOLDERS_RESPONSE_TREND = "FETCH_FOLDERS_RESPONSE_TREND"
export const FETCH_FOLDERS_NPS_TREND = "FETCH_AREA_NPS_TREND"

export const FETCH_FOLDERS_SATISFACTION_ANALYTICS = "FETCH_FOLDERS_SATISFACTION_ANALYTICS"
export const FETCH_FOLDERS_NPS_ANALYTICS = "FETCH_FOLDERS_NPS_ANALYTICS"
export const FETCH_FOLDERS_REVIEWS_COUNT = "FETCH_FOLDERS_REVIEWS_COUNT"

export const FETCH_REVIEWS_LIST = "FETCH_REVIEWS_LIST"
export const RESET_REVIEWS_LIST = "RESET_REVIEWS_LIST"
export const CHANGE_REVIEWS_PAGE = "CHANGE_REVIEWS_PAGE"
export const CHANGE_REVIEWS_STATUS = "CHANGE_REVIEWS_STATUS"

export const OPEN_TOAST = "OPEN_TOAST"
export const CLOSE_TOAST = "CLOSE_TOAST"

export const OPEN_MODAL = "OPEN_MODAL"
export const CLOSE_MODAL = "CLOSE_MODAL"

export const SET_PENDING = "SET_PENDING"

// loyalty

// coupons
export const CREATE_COUPON = "CREATE_COUPON"
export const FETCH_COUPONS = "FETCH_COUPONS"
export const RETRIEVE_COUPON = "RETRIEVE_COUPON"
export const UPDATE_COUPON = "UPDATE_COUPON"
export const DELETE_COUPON = "DELETE_COUPON"

// sprints
export const CREATE_SPRINT = "CREATE_SPRINT"
export const FETCH_SPRINTS = "FETCH_SPRINTS"
export const RETRIEVE_SPRINT = "RETRIEVE_SPRINT"
export const UPDATE_SPRINT = "UPDATE_SPRINT"
export const DELETE_SPRINT = "DELETE_SPRINT"

// rewards
export const FETCH_REWARDS = "FETCH_REWARDS"
export const FETCH_FOLLOWERS = "FETCH_FOLLOWERS"

// orders
export const FETCH_ORDERS = "FETCH_ORDERS"
export const SELECT_ORDER = "SELECT_ORDER"
export const DELETE_ORDER = "DELETE_ORDER"
export const DELETE_ORDER_ITEM = "DELETE_ORDER_ITEM"

// filters
export const SET_FILTERS = "SET_FILTERS"
export const CLEAR_FILTERS = "CLEAR_FILTERS"

// Regions
export const FETCH_REGIONS = "FETCH_REGIONS"
export const FETCH_REGION = "FETCH_REGION"
export const DELETE_REGION = "DELETE_REGION"
export const ADD_REGION = "ADD_REGION"
export const UPDATE_REGION = "UPDATE_REGION"
export const SELECT_REGION = "SELECT_REGION"
export const VIEW_REGION_AREAS = "VIEW_REGION_AREAS"
export const VIEW_REGION_BRANCHES = "VIEW_REGION_BRANCHES"

// Areas
export const FETCH_AREAS = "FETCH_AREAS"
export const FETCH_AREA = "FETCH_AREA"
export const DELETE_AREA = "DELETE_AREA"
export const ADD_AREA = "ADD_AREA"
export const UPDATE_AREA = "UPDATE_AREA"
export const SELECT_AREA = "SELECT_AREA"
export const VIEW_AREA_BRANCHES = "VIEW_AREA_BRANCHES"

// QR Templates
export const FETCH_TEMPLATES = "FETCH_TEMPLATES"
export const FETCH_TEMPLATE = "FETCH_TEMPLATE"
export const DELETE_TEMPLATE = "DELETE_TEMPLATE"
export const ADD_TEMPLATE = "ADD_TEMPLATE"
export const UPDATE_TEMPLATE = "UPDATE_TEMPLATE"
export const SELECT_TEMPLATE = "SELECT_TEMPLATE"

// Shortened URLS
export const FETCH_SHORTENED_URLS = "FETCH_SHORTENED_URLS"
export const FETCH_SHORTENED_URL = "FETCH_SHORTENED_URL"
export const DELETE_SHORTENED_URL = "DELETE_SHORTENED_URL"
export const SHORTEN_URL = "SHORTEN_URL"
export const UPDATE_SHORTENED_URL = "UPDATE_SHORTENED_URL"
