import { CLOSE_TOAST, OPEN_TOAST } from "../actions/types"

const INITIAL_STATE = { open: false, message: "", severity: "" }

export default (state = INITIAL_STATE, { type, payload }) => {
  switch (type) {
    case OPEN_TOAST:
      return { ...payload, open: true }
    case CLOSE_TOAST:
      return { open: false, message: "", severity: "" }
    default:
      return state
  }
}
