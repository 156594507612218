import { BASE_URL } from "src/Config"
import { getCurrentBrandId, getCurrentUser } from "src/utils/auth"
import axiosInstance from "./axios"

export const fetchSurveys = () => {
  const { user } = getCurrentUser()
  const brandId = getCurrentBrandId()
  const url = `${BASE_URL}/api/dashboards/surveys-v2/${brandId}/surveys/?${
    user.role === "manager" ? `branch=${user.location}` : ""
  }`
  return axiosInstance.get(url)
}

export const fetchSurveysFolders = (page, searchValue) => {
  const brandId = getCurrentBrandId()
  const url = `${BASE_URL}/api/dashboards/surveys-v2/${brandId}/folders/?page=${page}&limit=12&search=${searchValue}`
  return axiosInstance.get(url)
}

export const fetchSurveysFoldersShortList = () => {
  const brandId = getCurrentBrandId()
  const url = `${BASE_URL}/api/dashboards/surveys-v2/${brandId}/folders/short`
  return axiosInstance.get(url)
}

export const fetchFolderSurveys = (folderId) => {
  const brandId = getCurrentBrandId()
  const url = `${BASE_URL}/api/dashboards/surveys-v2/${brandId}/folders/${folderId}/`
  return axiosInstance.get(url)
}

export const createSurveysFolder = (folder) => {
  const brandId = getCurrentBrandId()
  return axiosInstance.post(`${BASE_URL}/api/dashboards/surveys-v2/${brandId}/folders/`, folder)
}

export const updateSurveysFolder = (folderId, folder) => {
  const brandId = getCurrentBrandId()
  return axiosInstance.put(`${BASE_URL}/api/dashboards/surveys-v2/${brandId}/folders/${folderId}/`, folder)
}

export const addSurveysToFolder = (folderId, surveys) => {
  const brandId = getCurrentBrandId()
  return axiosInstance.put(`${BASE_URL}/api/dashboards/surveys-v2/${brandId}/folders/${folderId}/add-surveys/`, {
    surveys: surveys,
  })
}

export const removeSurveysFromFolder = (folderId, surveys) => {
  const brandId = getCurrentBrandId()
  return axiosInstance.put(`${BASE_URL}/api/dashboards/surveys-v2/${brandId}/folders/${folderId}/remove-surveys/`, {
    surveys: surveys,
  })
}

export const deleteSurveysFolder = (folderId) => {
  const brandId = getCurrentBrandId()
  return axiosInstance.delete(`${BASE_URL}/api/dashboards/surveys-v2/${brandId}/folders/${folderId}/`)
}

export const createSurvey = (survey, brandId) => {
  return axiosInstance.post(`${BASE_URL}/api/dashboards/surveys-v2/${brandId}/surveys/`, survey)
}

export const retrieveSurvey = (brandId, id) => {
  return axiosInstance.get(`${BASE_URL}/api/dashboards/surveys-v2/${brandId}/surveys/${id}/`)
}

export const updateSurvey = (id, survey, brandId) => {
  return axiosInstance.put(`${BASE_URL}/api/dashboards/surveys-v2/${brandId}/surveys/${id}/`, survey)
}
export const deleteSurveyAPI = (id) => {
  const brandId = getCurrentBrandId()
  return axiosInstance.delete(`${BASE_URL}/api/dashboards/surveys-v2/${brandId}/surveys/${id}/`)
}

export const retrieveSurveyAnalytics = (id, filters) => {
  const brandId = getCurrentBrandId()
  return axiosInstance.get(`${BASE_URL}/api/dashboards/surveys-v2/${brandId}/analytics/${id}/${filters}`)
}

export const exportSurveyAnalytics = (id, filters) => {
  const brandId = getCurrentBrandId()
  return axiosInstance({
    method: "GET",
    url: `${BASE_URL}/api/dashboards/surveys-v2/${brandId}/analytics/${id}/export${filters}`,
    responseType: "blob",
  })
}

export const exportSurveyReviews = (id, filters) => {
  const brandId = getCurrentBrandId()
  return axiosInstance({
    method: "GET",
    url: `${BASE_URL}/api/dashboards/surveys-v2/${brandId}/responses/${id}/export${filters}`,
    responseType: "blob",
  })
}

export const retrieveTextQuestionAnalytics = (questionId, page) => {
  const brandId = getCurrentBrandId()
  return axiosInstance.get(
    `${BASE_URL}/api/dashboards/surveys-v2/${brandId}/analytics/question/${questionId}/?page=${page}`
  )
}

export const fetchBrandQuestions = (page, searchValue = "") => {
  const brandId = getCurrentBrandId()
  const url = `${BASE_URL}/api/dashboards/surveys-v2/${brandId}/questions/?page=${page}&limit=12&search=${searchValue}`
  return axiosInstance.get(url)
}
