import React from "react"

export const AddBrandIcon = () => {
  return (
    <svg width="90%" height="90%" viewBox="0 0 77 77" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M38.5 0C17.325 0 0 17.325 0 38.5C0 59.675 17.325 77 38.5 77C59.675 77 77 59.675 77 38.5C77 17.325 59.675 0 38.5 0ZM38.5 69.3C21.56 69.3 7.7 55.44 7.7 38.5C7.7 21.56 21.56 7.7 38.5 7.7C55.44 7.7 69.3 21.56 69.3 38.5C69.3 55.44 55.44 69.3 38.5 69.3Z"
        fill="#79869F"
        fillOpacity="0.52"
      />
      <path
        d="M60.6375 38.5C60.6375 40.6175 58.905 42.35 56.7875 42.35H42.35V56.7875C42.35 58.905 40.6175 60.6375 38.5 60.6375C36.3825 60.6375 34.65 58.905 34.65 56.7875V42.35H20.2125C18.095 42.35 16.3625 40.6175 16.3625 38.5C16.3625 36.3825 18.095 34.65 20.2125 34.65H34.65V20.2125C34.65 18.095 36.3825 16.3625 38.5 16.3625C40.6175 16.3625 42.35 18.095 42.35 20.2125V34.65H56.7875C58.905 34.65 60.6375 36.3825 60.6375 38.5Z"
        fill="#79869F"
        fillOpacity="0.52"
      />
    </svg>
  )
}

export default AddBrandIcon
